import {Component, OnInit} from '@angular/core';
import {DropDown} from '@app/shared/animations/animations';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['../../../app.component.sass', 'landingpage.component.sass'],
  animations: [DropDown]
})
export class LandingpageComponent implements OnInit {
  isVoImportanceOpen = false;
  isLawOpen = false;
  isRisksOpen = false;
  isChancesOpen = false;
  isComparisonOpen = false;

  constructor() { }

  ngOnInit() {
  }

  toggleVoImportance() {
    this.isVoImportanceOpen = !this.isVoImportanceOpen;
    {
      this.isLawOpen = this.isLawOpen = false;
    }
  }

  toggleLaw() {
    this.isLawOpen = !this.isLawOpen;
    {
      this.isVoImportanceOpen = false;
    }
  }

  toggleRisks() {
    this.isRisksOpen = !this.isRisksOpen;
    {
      this.isChancesOpen = false;
      this.isComparisonOpen = false;
    }
  }

  toggleChances() {
    this.isChancesOpen = !this.isChancesOpen;
    {
      this.isRisksOpen = false;
      this.isComparisonOpen = false;
    }
  }

  toggleComparison() {
    this.isComparisonOpen = !this.isComparisonOpen;
    {
      this.isRisksOpen = false;
      this.isChancesOpen = false;
    }
  }
}
