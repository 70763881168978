import {Component, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {InternetExplorerHelperService} from "@app/shared/services/internetExplorerHelper/internet-explorer-helper.service";

@Component({
  selector: 'app-comfort',
  templateUrl: './comfort.component.html',
  styleUrls: ['../../../app.component.sass', '../../pages/landingpage/landingpage.component.sass'],
  animations: [ trigger('Reshaper', [
      transition(':enter', [
        style({height: '735px'}),
        animate('500ms', style({height: '*'}))
      ]),
      transition(':leave', [
        style({height: '*'}),
        animate('500ms', style({height: '735px'}))
      ])
    ])
  ]
})
export class ComfortComponent implements OnInit {

  public opened: boolean;
  cssClass: string;

  constructor(private internetExplorerHelper: InternetExplorerHelperService) {
    this.opened = false;
  }

  ngOnInit() {
    this.cssClass = this.internetExplorerHelper.addCssClass('pt-3');
  }
}
