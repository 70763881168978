import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DropDown } from '@app/shared/animations/animations';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['../../../../app.component.sass', '../../../pages/landingpage/landingpage.component.sass'],
  animations: [DropDown]
})
export class BasicComponent implements OnInit {

  basicForm = new FormGroup({
    entgeltumwandlung: new FormControl('', Validators.required),
    arbeitgeberzuschuss: new FormControl('')
  });

  constructor() {

  }

  get entgeltumwandlung() {
    return this.basicForm.get('entgeltumwandlung');
  }

  get arbeitgeberzuschuss() {
    return this.basicForm.get('arbeitgeberzuschuss');
  }

  ngOnInit() {
  }

  onSubmit() {
    this.downloadDocument();
  }

  private downloadDocument() {
    const docx = this.findSuitableDocument();
    saveAs('../../../../assets/docs/' + docx, docx);
  }

  isFourPercentAtEntgeltumwandlungSelected() {
    return this.entgeltumwandlung.value === 'false';
  }

  findSuitableDocument() {
    if (this.isFourPercentAtEntgeltumwandlungSelected()) {
      if (this.arbeitgeberzuschuss.value === 'true') {
        return 'Muster_VO_Basis_DV_8_Prozent_Zuschuss_bis_4_Prozent.docx';
      } else {
        return 'Muster_VO_Basis_DV_8_Prozent_Zuschuss_bis_8_Prozent.docx';
      }
    }
    return 'Muster_VO_Basis_DV_4_Prozent_Zuschuss_bis_4_Prozent.docx';
  }


  setControlValidation(bool: boolean) {
    if (this.arbeitgeberzuschuss !== null) {
      if (bool) {
        this.arbeitgeberzuschuss.setValidators(Validators.required);
      } else {
        this.arbeitgeberzuschuss.clearValidators();
      }
      this.arbeitgeberzuschuss.updateValueAndValidity();
    }
  }
}
