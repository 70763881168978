import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from '@app/shared/services/form/form.service';
import { ProgressbarService } from '@app/shared/services/progressbar/progressbar.service';
import { PageEnum } from '@app/core/enums/page-enum';
import { Observable } from 'rxjs';
import { ConverterService } from '@app/shared/services/converter/converter.service';

@Component({
  selector: 'app-order-placement-form',
  templateUrl: './order-placement-form.component.html',
  styleUrls: ['../../../../..//app.component.sass']
})
export class OrderPlacementFormComponent implements OnInit {

  orderPlacementForm: FormGroup;

  @Output() pageChanged = new EventEmitter<number>();
  @Output() scrollTop = new EventEmitter<boolean>();
  premium: Observable<boolean>;
  premiumValue: boolean;

  constructor(private formService: FormService, private converterService: ConverterService,
              private progressBarService: ProgressbarService) {
  }

  ngOnInit() {
    this.orderPlacementForm = this.formService.auftragserteilungsForm;
    this.scrollTop.emit(true);
    this.premium = this.formService.isPremium;
    this.premium.subscribe(value => this.premiumValue = value);
  }

  onSubmit() {
    this.converterService.sendPostRequest().subscribe(
      (response) => {
        if (response.message === 'Email erfolgreich gesendet.') {
          this.pageChanged.emit(PageEnum.FORM_FINAL);
          this.progressBarService.increasePercentage(100);
        }},
      (error) => {
        this.pageChanged.emit(PageEnum.FORM_ERROR);
        this.progressBarService.decreasePercentage(92);
      });
    this.formService.resetFormData();
  }

  previousPage() {
    this.formService.isPremium.subscribe(isPremium => {
      if (this.formService.operationalForm.get('bestehtVo').value === 'true') {
        this.pageChanged.emit(PageEnum.FORM_REMARKS);
      } else if (isPremium) {
        this.pageChanged.emit(PageEnum.FORM_LAWYER);
      } else {
        this.pageChanged.emit(PageEnum.FORM_REMARKS);
      }
      this.progressBarService.decreasePercentage(this.formService.PROCESS_STEP);
    });
  }

  getSubmitText() {
    return (this.premiumValue) ? 'Mandatsanfrage' : 'Kostenpflichtiger Auftrag';
  }

  getCurrentPage() {
    if (this.formService.operationalForm.get('bestehtVo').value === 'true') {
      return '13';
    } else if (this.premiumValue) {
      return '14';
    } else {
      return '13';
    }
  }

  getOrderType() {
    return (this.premiumValue) ? 'Mandatsanfrage' : 'Auftragserteilung';
  }

  isFormValid() {
    const mandatsBerechtigungCheckboxValid = this.formService.auftragserteilungsForm.get('mandatsBerechtigung').valid ;
    const mandatsUebermittlungCheckboxValid = this.formService.auftragserteilungsForm.get('mandatsUebermittlung').valid;

    const agbCheckboxValid = this.formService.auftragserteilungsForm.get('agb').valid;
    const nbbBerechtigungCheckboxValid = this.formService.auftragserteilungsForm.get('nbbBerechtigung').valid;
    const einverstaendnisUebermittlungCheckboxValid = this.formService.auftragserteilungsForm.get('einverstaendnisUebermittlung').valid;

    if (this.premiumValue && mandatsBerechtigungCheckboxValid && mandatsUebermittlungCheckboxValid) {
      return true;
    } else if (!this.premiumValue && agbCheckboxValid && nbbBerechtigungCheckboxValid && einverstaendnisUebermittlungCheckboxValid) {
      return true;
    } else {
      return false;
    }
  }
}
