import { Injectable } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import {
  customEmailValidator,
  dateValidator,
  dotInNumberValidator,
  nameValidator,
  phoneValidator,
  postCodeValidator,
  requireCheckboxesToBeCheckedValidator,
  streetValidator,
  trimValidator,
} from "@app/core/form-validators/custom-validators";
import { questions } from "@app/core/models/questions";
import { FormData } from "@app/core/models/form-data";
import { information } from "@app/core/models/information";

@Injectable({
  providedIn: "root",
})
export class FormService {
  private isPremiumSource = new BehaviorSubject<boolean>(false);
  public isPremium = this.isPremiumSource.asObservable();

  public PROCESS_STEP = 7;

  public companyForm: FormGroup;
  public consultingForm: FormGroup;
  public invoiceForm: FormGroup;
  public operationalForm: FormGroup;
  public serviceForm: FormGroup;
  public leistungsSystematikForm: FormGroup;
  public bavForm: FormGroup;
  public arbeitgeberZuschussForm: FormGroup;
  public bavFoerderungForm: FormGroup;
  public vwlForm: FormGroup;
  public auftragserteilungsForm: FormGroup;
  public versorgungstraegerForm: FormGroup;
  public lawyerForm: FormGroup;
  public anmerkungenForm: FormGroup;
  private isMapped: boolean;

  constructor(private formBuilder: FormBuilder) {
    this.resetFormData();
  }

  setPremium() {
    this.isPremiumSource.next(true);
  }

  setComfort() {
    this.isPremiumSource.next(false);
  }

  mapBetterForm(): Array<FormData> {
    // Wird benötigt das die Methode nur einmal ausgeführt wird andernfalls werden die Werte übschrieben
    if (!this.isMapped) {
      this.assignCorrectValues();
      this.isMapped = true;
    }
    const formData = new Array<FormData>();
    formData.push(this.createDto(this.companyForm, "Firmendaten"));
    formData.push(this.createDto(this.consultingForm, "Beratung"));
    formData.push(this.createDto(this.invoiceForm, "Rechnungsadresse"));
    formData.push(
      this.createDto(this.operationalForm, "Betriebliches Regelungswerk")
    );
    formData.push(this.createDto(this.serviceForm, "Leistungsarten"));
    formData.push(
      this.createDto(this.leistungsSystematikForm, "Leistungssystematik")
    );
    formData.push(this.createDto(this.bavForm, "BAV"));
    formData.push(
      this.createDto(this.arbeitgeberZuschussForm, "Arbeitgeberzuschuss")
    );
    formData.push(this.createDto(this.bavFoerderungForm, "Foerderung_der_BAV"));
    formData.push(this.createDto(this.vwlForm, "VWL"));
    formData.push(
      this.createDto(this.versorgungstraegerForm, "Versorgungstraeger")
    );
    formData.push(this.createDto(this.anmerkungenForm, "Anmerkungen"));
    formData.push(this.createDto(this.lawyerForm, "Anwalt"));
    formData.push(
      this.createDto(this.auftragserteilungsForm, "Auftragserteilung")
    );
    return formData;
  }

  createDto(group: FormGroup, title: string): FormData {
    const formGroup = {} as FormData;
    formGroup.name = title;
    formGroup.control = [];

    Object.keys(group.controls).forEach((key) => {
      if (this.isNestedControl(key)) {
        const nestedFormGroup: any = group.controls[key];

        Object.keys(nestedFormGroup.controls).forEach((nestedKey) => {
          formGroup.control.push({
            question: questions[nestedKey],
            answer: this.filterForbiddenChars(
              nestedFormGroup.get(nestedKey).value
            ),
            key: nestedKey,
            information: information[nestedKey],
          });
        });
      } else {
        formGroup.control.push({
          question: questions[key],
          answer: this.filterForbiddenChars(group.get(key).value),
          key,
          information: information[key],
        });
      }
    });

    return formGroup;
  }

  isNestedControl(key: string) {
    return key === "durchfuehrungsweg";
  }

  filterForbiddenChars(value) {
    if (typeof value === "string") {
      value = value.split(";").join("");
      value = value.split("#").join("");
      value = value.split("\n").join("");
      return value.replace(/(?:\r|\n|\r\n)/g, " ");
    }
    return value;
  }

  resetFormData() {
    this.companyForm = new FormGroup({
      firmenName: new FormControl("", [Validators.required, nameValidator]),
      strasse: new FormControl("", [
        Validators.required,
        trimValidator,
        streetValidator,
      ]),
      plz: new FormControl("", [Validators.required, postCodeValidator]),
      stadt: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        trimValidator,
      ]),
    });

    this.consultingForm = this.formBuilder.group({
      agentenName: new FormControl("", [Validators.required, nameValidator]),
      agentenNummer: new FormControl("", [
        Validators.required,
        Validators.pattern("^\\d{9}$"),
      ]),
      agentenStrasse: new FormControl("", [
        Validators.required,
        trimValidator,
        streetValidator,
      ]),
      agentenPlz: new FormControl("", [Validators.required, postCodeValidator]),
      agentenStadt: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        trimValidator,
      ]),
      agentenTelefon: new FormControl("", [phoneValidator]),
      agentenMobil: new FormControl("", [phoneValidator]),
      agentenEmail: new FormControl("", [
        Validators.required,
        customEmailValidator,
        trimValidator,
      ]),
      agentenFax: new FormControl("", [phoneValidator]),
      agentenPasswort: new FormControl(""),
    });

    this.invoiceForm = this.formBuilder.group({
      firma: new FormControl(false, Validators.required),
      vermittler: new FormControl(false, Validators.required),
      vermittlerErklaerung: new FormControl(false),
      dritter: new FormControl(false, Validators.required),
      dritterName: new FormControl("", [Validators.required, nameValidator]),
      dritterStrasse: new FormControl("", [
        Validators.required,
        trimValidator,
        streetValidator,
      ]),
      dritterOrt: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        trimValidator,
      ]),
      dritterPlz: new FormControl("", [Validators.required, postCodeValidator]),
    });

    this.operationalForm = this.formBuilder.group({
      tarifBindung: new FormControl(null, Validators.required),
      sonstigeBeschreibung: new FormControl(""),
      betriebsrat: new FormControl(null, Validators.required),
      betriebsratUnterschrift: new FormControl(null),
      bestehtVo: new FormControl(null, Validators.required),
      ueberpruefeVo: new FormControl(null),
      ueberpruefeVoPopup: new FormControl(false),
      bestehendeVoDatum: new FormControl(""),
      inkrafttretenTermin: new FormControl("", Validators.required),
      inkrafttretenTerminDatum: new FormControl(""),
      inkrafttretenUnterschrift: new FormControl(false),
    });

    this.serviceForm = new FormGroup(
      {
        altersRente: new FormControl(false, Validators.required),
        beitragsbefreiungBU: new FormControl(false, Validators.required),
        berufsunfaehigkeitsRente: new FormControl(false, Validators.required),
        grundunfaehigkeitsRente: new FormControl(false, Validators.required),
        hinterbliebenenRente: new FormControl(false, Validators.required),
      },
      requireCheckboxesToBeCheckedValidator()
    );

    this.leistungsSystematikForm = this.formBuilder.group({
      leistungsZusage: new FormControl("true", Validators.required),
    });

    this.bavForm = new FormGroup({
      arbeitnehmerDVmitVierProzent: new FormControl(false),
      arbeitnehmerUKmitVierProzent: new FormControl(false),
      arbeitnehmerDVmitUeberProzent: new FormControl(false),
      arbeitnehmerUKmitUeberProzent: new FormControl(false),
      leitendeDVmitUeberProzent: new FormControl(false),
      leitendeUKmitUeberProzent: new FormControl(false),
      sonstige1text: new FormControl(""),
      sonstige1DVmitUeberProzent: new FormControl(false),
      sonstige1UKmitUeberProzent: new FormControl(false),
      sonstige2text: new FormControl(""),
      sonstige2DVmitUeberProzent: new FormControl(false),
      sonstige2UKmitUeberProzent: new FormControl(false),
      sonstige3text: new FormControl(""),
      sonstige3DVmitUeberProzent: new FormControl(false),
      sonstige3UKmitUeberProzent: new FormControl(false),
    });

    this.arbeitgeberZuschussForm = this.formBuilder.group({
      zuschussUnterstuetzungskasse: new FormControl(null),
      entgeltumwandlungshoeheRelativ: new FormControl(""),
      entgeltumwandlungshoeheAbsolut: new FormControl(""),
      entgeltumwandlungshoehe: new FormControl(null, Validators.required),
      entgeltumwandlungseinheit: new FormControl(false),
      berechnungsZuschusspauschal: new FormControl("true"),
      berechnungZuschussspitz: new FormControl(false),
      entgeltZuschussOberhalbVierProzent: new FormControl(
        "false",
        Validators.required
      ),
      andereBegrenzung: new FormControl(""),
      zuschussUkGleich: new FormControl("false", Validators.required),
      andereBezuschussung: new FormControl(""),
      entgeltumwandlungsZeitpunkt: new FormControl("true", Validators.required),
      sonstigeZuschuss: new FormControl(""),
    });

    this.bavFoerderungForm = new FormGroup({
      arbeitgeberBeitrag: new FormControl("", Validators.required),
      monatlicheBeitragshoehe: new FormControl("", [
        Validators.required,
        Validators.min(1),
        dotInNumberValidator,
      ]),
      durchfuehrungsweg: new FormGroup(
        {
          durchfuehrungsWegDV: new FormControl(false),
          durchfuehrungsWegUK: new FormControl(false),
        },
        requireCheckboxesToBeCheckedValidator()
      ),
      ausnahmeAzubi: new FormControl(false),
      ausnahmeBefristet: new FormControl(false),
      ausnahmeProbezeit: new FormControl(false),
      ausnahmeAushilfen: new FormControl(false),
      ausnahmeBetriebszugehoerigkeit: new FormControl(false),
      ausnahmePremium: new FormControl(false),
      ausnahmePremiumText: new FormControl(""),
      betriebszugehoerigkeitInMonaten: new FormControl(),
      behandlungTeilzeitkraefteBav: new FormControl(null, Validators.required),
      unverfallberkeitsregelung: new FormControl(null, Validators.required),
      sonstigesBav: new FormControl(""),
    });

    this.vwlForm = this.formBuilder.group({
      derzeitigeZahlungen: new FormControl(null, Validators.required),
      bavStattVwl: new FormControl(null),
      artVwlEntgeltumwandlung: new FormControl(""),
      artVwlEntgeltumwandlungBeitragshoehe: new FormControl(
        null,
        dotInNumberValidator
      ),
      behandlungTeilzeitkraefteVwl: new FormControl(null),
      sonstigesVwl: new FormControl(""),
    });

    this.versorgungstraegerForm = this.formBuilder.group({
      weitererAnbieterDV: new FormControl("", Validators.required),
      weitererAnbieterDVpremium: new FormControl(""),
      weitererAnbieterUK: new FormControl("", Validators.required),
      weitererAnbieterUKpremium: new FormControl(""),
    });

    this.anmerkungenForm = this.formBuilder.group({
      anmerkungen: new FormControl(""),
    });

    this.lawyerForm = this.formBuilder.group({
      lawyer: new FormControl("true", Validators.required),
    });

    this.auftragserteilungsForm = this.formBuilder.group({
      agb: new FormControl(false, Validators.pattern("true")),
      nbbBerechtigung: new FormControl(false, Validators.pattern("true")),
      einverstaendnisUebermittlung: new FormControl(
        false,
        Validators.pattern("true")
      ),
      mandatsBerechtigung: new FormControl(false, Validators.pattern("true")),
      mandatsUebermittlung: new FormControl(false, Validators.pattern("true")),
    });
    this.isMapped = false;
  }

  clearPremiumFields() {
    const agentenPasswort = this.consultingForm.get("agentenPasswort");

    const tarifBindung = this.operationalForm.get("tarifBindung");
    const sonstigeBeschreibung = this.operationalForm.get(
      "sonstigeBeschreibung"
    );
    const betriebsratUnterschrift = this.operationalForm.get(
      "betriebsratUnterschrift"
    );
    const bestehtVo = this.operationalForm.get("bestehtVo");

    const leistungsZusage = this.leistungsSystematikForm.get("leistungsZusage");

    const berechnungZuschussspitz = this.arbeitgeberZuschussForm.get(
      "berechnungZuschussspitz"
    );
    const entgeltZuschussOberhalbVierProzent = this.arbeitgeberZuschussForm.get(
      "entgeltZuschussOberhalbVierProzent"
    );
    const zuschussUkGleich =
      this.arbeitgeberZuschussForm.get("zuschussUkGleich");
    const andereBegrenzung =
      this.arbeitgeberZuschussForm.get("andereBegrenzung");
    const andereBezuschussung = this.arbeitgeberZuschussForm.get(
      "andereBezuschussung"
    );

    const sonstigeZuschuss =
      this.arbeitgeberZuschussForm.get("sonstigeZuschuss");

    const ausnahmePremium = this.bavFoerderungForm.get("ausnahmePremium");
    const ausnahmePremiumText = this.bavFoerderungForm.get(
      "ausnahmePremiumText"
    );
    const sonstigesBav = this.bavFoerderungForm.get("sonstigesBav");

    const artVwlEntgeltumwandlung = this.vwlForm.get("artVwlEntgeltumwandlung");
    const artVwlEntgeltumwandlungBeitragshoehe = this.vwlForm.get(
      "artVwlEntgeltumwandlungBeitragshoehe"
    );
    const sonstigesVwl = this.vwlForm.get("sonstigesVwl");

    const anmerkungen = this.anmerkungenForm.get("anmerkungen");

    const lawyer = this.lawyerForm.get("lawyer");

    const mandatsBerechtigung = this.auftragserteilungsForm.get(
      "mandatsBerechtigung"
    );
    const mandatsUebermittlung = this.auftragserteilungsForm.get(
      "mandatsUebermittlung"
    );

    agentenPasswort.patchValue("");

    tarifBindung.patchValue(null);
    sonstigeBeschreibung.patchValue("");
    betriebsratUnterschrift.patchValue(null);
    bestehtVo.patchValue(null);

    leistungsZusage.patchValue("true");
    berechnungZuschussspitz.patchValue(false);
    entgeltZuschussOberhalbVierProzent.patchValue("false");
    zuschussUkGleich.patchValue("false");
    andereBegrenzung.patchValue("");
    andereBezuschussung.patchValue("");
    sonstigeZuschuss.patchValue("");
    ausnahmePremium.patchValue(false);
    ausnahmePremiumText.patchValue("");
    sonstigesBav.patchValue("");
    artVwlEntgeltumwandlung.patchValue("");
    artVwlEntgeltumwandlungBeitragshoehe.patchValue(null);
    sonstigesVwl.patchValue("");
    anmerkungen.patchValue("");
    lawyer.patchValue("");

    mandatsBerechtigung.patchValue(false);
    mandatsUebermittlung.patchValue(false);
  }

  private assignCorrectValues() {
    this.assignEmployerPromotionValues();
    this.assignDefaultLawyer();
    this.assignOperationalValues();
    this.assignInvoiceValues();
  }

  private assignDefaultLawyer() {
    const lawyer = this.lawyerForm.get("lawyer");
    if (lawyer.value === "") {
      lawyer.patchValue("true");
    }
  }

  private assignEmployerPromotionValues() {
    const entgeltumwandlungshoehe = this.arbeitgeberZuschussForm.get(
      "entgeltumwandlungshoehe"
    );
    const entgeltumwandlungshoeheRelativ = this.arbeitgeberZuschussForm.get(
      "entgeltumwandlungshoeheRelativ"
    );
    const entgeltumwandlungseinheit = this.arbeitgeberZuschussForm.get(
      "entgeltumwandlungseinheit"
    );
    const entgeltumwandlungshoeheAbsolut = this.arbeitgeberZuschussForm.get(
      "entgeltumwandlungshoeheAbsolut"
    );
    const berechnungsZuschusspauschal = this.arbeitgeberZuschussForm.get(
      "berechnungsZuschusspauschal"
    );
    const berechnungZuschussspitz = this.arbeitgeberZuschussForm.get(
      "berechnungZuschussspitz"
    );

    if (entgeltumwandlungshoehe.value === "option3") {
      entgeltumwandlungshoehe.patchValue(entgeltumwandlungshoeheRelativ.value);
      entgeltumwandlungseinheit.patchValue(false);
    } else if (entgeltumwandlungshoehe.value === "option4") {
      entgeltumwandlungshoehe.patchValue(entgeltumwandlungshoeheAbsolut.value);
      entgeltumwandlungseinheit.patchValue(true);
    }

    if (berechnungsZuschusspauschal.value === "false") {
      berechnungZuschussspitz.patchValue(true);
    }
  }

  private assignOperationalValues() {
    const inkrafttretenTerminDatum = this.operationalForm.get(
      "inkrafttretenTerminDatum"
    );
    const inkrafttretenTermin = this.operationalForm.get("inkrafttretenTermin");
    const inkrafttretenUnterschrift = this.operationalForm.get(
      "inkrafttretenUnterschrift"
    );

    if (inkrafttretenTermin.value === "yesTerminDatum") {
      inkrafttretenTermin.patchValue(inkrafttretenTerminDatum.value);
    } else {
      inkrafttretenUnterschrift.patchValue(true);
      inkrafttretenTermin.patchValue("");
    }
  }

  private assignInvoiceValues() {
    const InvoiceValueGroup = "dritter";
    if (this.invoiceForm.get(InvoiceValueGroup).value === "firma") {
      this.invoiceForm.get("firma").patchValue(true);
    } else {
      this.invoiceForm.get("firma").patchValue(false);
    }
    if (this.invoiceForm.get(InvoiceValueGroup).value === "vermittler") {
      this.invoiceForm.get("vermittler").patchValue(true);
      this.invoiceForm.get("vermittlerErklaerung").patchValue(true);
    } else {
      this.invoiceForm.get("vermittlerErklaerung").patchValue(false);
    }
    if (this.invoiceForm.get(InvoiceValueGroup).value === "dritter") {
      this.invoiceForm.get("dritter").patchValue(true);
    } else {
      this.invoiceForm.get("dritter").patchValue(false);
    }
  }
}
