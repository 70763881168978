import {Component, OnInit} from '@angular/core';
import {InternetExplorerHelperService} from '@app/shared/services/internetExplorerHelper/internet-explorer-helper.service';

@Component({
  selector: 'app-premium',
  templateUrl: './premium.component.html',
  styleUrls: ['../../../app.component.sass', '../landingpage/landingpage.component.sass']
})
export class PremiumComponent implements OnInit {

  cssClass: string;

  constructor(private internetExplorerHelper: InternetExplorerHelperService) {
  }

  ngOnInit() {
    this.cssClass = this.internetExplorerHelper.addCssClass('pt-3');
  }
}
