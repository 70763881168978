import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InternetExplorerHelperService {

  constructor() { }


  checkInternetExplorer(): boolean {
    const userAgent = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    const isIe = userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1;

    return isIe;
  }

  addCssClass(cssClass: string): string {
    if (this.checkInternetExplorer()) {
      return cssClass;
    }
    return '';
  }
}
