import { Component, OnInit } from '@angular/core';
import { FormService } from '@app/shared/services/form/form.service';

@Component({
  selector: 'app-model-bar',
  templateUrl: './model-bar.component.html',
  styleUrls: ['./model-bar.component.sass']
})
export class ModelBarComponent implements OnInit {

  premium = false;

  constructor(private formService: FormService) {
  }

  ngOnInit() {
    this.formService.isPremium.subscribe(value => {
      this.premium = value;
    });
  }

  getModel(): string {
    if (!this.premium) {
      return 'Comfort';
    }
    return 'Premium';
  }

  setModelColor(): string {
    if (this.premium) {
      return 'premium-footer';
    }
    return 'footer';
  }
}
