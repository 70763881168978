import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormService } from "@app/shared/services/form/form.service";
import { ProgressbarService } from "@app/shared/services/progressbar/progressbar.service";
import { PageEnum } from "@app/core/enums/page-enum";
import { DropDown } from "@app/shared/animations/animations";

@Component({
  selector: "app-lawyer",
  templateUrl: "./lawyer.component.html",
  styleUrls: ["../../../../../app.component.sass"],
  animations: [DropDown],
})
export class LawyerComponent implements OnInit {
  lawyerForm: FormGroup;
  isPremium: boolean;

  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() scrollTop = new EventEmitter<boolean>();

  constructor(
    private formService: FormService,
    private progressBarService: ProgressbarService
  ) {}

  ngOnInit() {
    this.lawyerForm = this.formService.lawyerForm;
    this.formService.isPremium.subscribe((status) => {
      this.isPremium = status;
    });
    this.scrollTop.emit(true);
  }

  onSubmit() {
    this.pageChanged.emit(PageEnum.FORM_ORDER_PLACEMENT);
    this.progressBarService.increasePercentage(this.formService.PROCESS_STEP);
  }

  previousPage() {
    this.pageChanged.emit(PageEnum.FORM_REMARKS);
    this.progressBarService.decreasePercentage(this.formService.PROCESS_STEP);
  }
}
