import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormService } from '@app/shared/services/form/form.service';
import { ProgressbarService } from '@app/shared/services/progressbar/progressbar.service';
import { PageEnum } from '@app/core/enums/page-enum';
import { Observable } from 'rxjs';
import { noFutureDateValidator, dateValidator, validDate } from '@app/core/form-validators/custom-validators';
import { DropDown } from '@app/shared/animations/animations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-operational-form',
  templateUrl: './operational-form.component.html',
  styleUrls: ['../../../../..//app.component.sass'],
  animations: [DropDown]
})
export class OperationalFormComponent implements OnInit {

  operationalForm: FormGroup;
  @Output() pageChanged = new EventEmitter<number>();
  @Output() showPremiumModal = new EventEmitter<boolean>();
  @Output() scrollTop = new EventEmitter<boolean>();
  premium: Observable<boolean>;
  premiumModel = false;


  constructor(private progressbarService: ProgressbarService, private formService: FormService, private modalService: NgbModal) {
  }

  ngOnInit() {
    this.operationalForm = this.formService.operationalForm;
    this.premium = this.formService.isPremium;
    this.scrollTop.emit(true);
    this.premium.subscribe(value => {
      this.premiumModel = value;
      this.removeNotNeededValidators();
    });

    this.operationalForm.get('ueberpruefeVo').valueChanges.subscribe(value => {
      if (value === 'false' && this.premiumModel === false) {
        this.operationalForm.get('bestehendeVoDatum').setValidators([Validators.required, noFutureDateValidator]);
      }
    }
    );

    this.operationalForm.get('bestehtVo').valueChanges.subscribe(value => {
      if (value === 'false' && this.premiumModel === false) {
        this.removeNotNeededValidators();
      } else {
        this.operationalForm.get('bestehendeVoDatum').setValidators([Validators.required, noFutureDateValidator]);
      }
    });
  }

  removeNotNeededValidators() {
    this.operationalForm.get('bestehendeVoDatum').clearValidators();
    this.operationalForm.get('bestehendeVoDatum').updateValueAndValidity();
  }

  onSubmit() {
    this.pageChanged.emit(PageEnum.FORM_SERVICES);
    this.progressbarService.increasePercentage(this.formService.PROCESS_STEP);
  }

  previousPage() {
    this.pageChanged.emit(PageEnum.FORM_INVOICE_ADDRESS);
    this.progressbarService.decreasePercentage(this.formService.PROCESS_STEP);
  }

  setControlValidations(bool: boolean, key: string) {
    if (bool) {
      if (key === 'sonstigeBeschreibung' && !this.premiumModel) {
        return;
      }
    }

    const field = this.operationalForm.get(key);

    if (field !== null) {
      if (bool) {
        field.setValidators(Validators.required);
        if (key === 'inkrafttretenTerminDatum') {
          field.setValidators([Validators.required, dateValidator, validDate]);
        }
      } else {
        field.clearValidators();
      }
      field.updateValueAndValidity();
    }
  }

  get tarifBindung() {
    return this.operationalForm.get('tarifBindung');
  }

  get sonstigeBeschreibung() {
    return this.operationalForm.get('sonstigeBeschreibung');
  }

  get betriebsrat() {
    return this.operationalForm.get('betriebsrat');
  }

  get betriebsratUnterschrift() {
    return this.operationalForm.get('betriebsratUnterschrift');
  }

  get bestehtVo() {
    return this.operationalForm.get('bestehtVo');
  }

  get inkrafttretenTermin() {
    return this.operationalForm.get('inkrafttretenTermin');
  }

  isFormValid() {
    if (this.betriebsrat.value === 'true') {
      if (!this.betriebsratUnterschrift.value) {
        return false;
      }
    }
    return this.operationalForm.valid;
  }

  checkAndSetVOueberpruefung() {
    if (this.operationalForm.get('ueberpruefeVoPopup').value === false) {
      this.operationalForm.get('ueberpruefeVo').patchValue(null);
    }
  }


  openPopup(event, template: TemplateRef<any>) {
    // This is needed for avoiding the ExpressionChangedAfterItHasBeenCheckedError
    // see -> https://medium.xcom/code-divoire/ng-bootstrap-modalservice-and-expressionchangedafterithasbeencheckederror-7b21cbf6c74a
    event.srcElement.blur();
    event.preventDefault();
    if (this.operationalForm.get('ueberpruefeVo').value === 'false') {
      this.modalService.open(template, {centered: true, size: 'lg'});
    }
  }
}
