import { Routes } from '@angular/router';
import { LandingpageComponent } from '@app/view/pages/landingpage/landingpage.component';
import { StylingpageComponent } from '@app/view/pages/stylingpage/stylingpage.component';
import { ComfortComponent } from '@app/view/pages/comfort/comfort.component';
import { BasisComponent } from '@app/view/pages/basis/basis.component';
import { PremiumComponent } from '@app/view/pages/premium/premium.component';
import {environment} from '../../environments/environment';

const stylingRoute = {path: 'stylingpage', component: StylingpageComponent};

const routes: Routes = [...[
  {
    path: 'versorgungsordnung/:model',
    loadChildren: () => import('./../view/models/form.module').then(m => m.FormModule)
  },
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: LandingpageComponent},
  {path: 'comfort', component: ComfortComponent},
  {path: 'basis', component: BasisComponent},
  {path: 'premium', component: PremiumComponent},
]]

if (!environment.production) {
  routes.push(stylingRoute);
}

export  {routes};
