import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import { ModalComponent } from '@app/view/models/modal/modal.component';
import { FormService } from '@app/shared/services/form/form.service';

@Directive({
  selector: '[appModal]'
})

export class ModalDirective {
  @Input() componentName: string;
  @Input() isTextArea: string;
  isPremium: boolean;

  constructor(
    private el: ElementRef,
    private ren: Renderer2,
    private viewContainer: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private formService: FormService
  ) {
  }

  @HostListener('click')
  openModal() {
    this.formService.isPremium.subscribe(value => {
      this.isPremium = value;
      if (!this.isPremium) {
        this.createModalDialog(ModalComponent);
      }
    });
  }

  createModalDialog(modalDialogComponent) {
    this.viewContainer.clear();
    const modalDialogComponentFactory = this.componentFactoryResolver.resolveComponentFactory(modalDialogComponent);
    const modalDialogComponentRef = this.viewContainer.createComponent(modalDialogComponentFactory);
    modalDialogComponentRef.instance[this.componentName] = this.componentName;
    // @ts-ignore
    modalDialogComponentRef.instance.isTextArea = this.isTextArea;
    return modalDialogComponentRef;
  }


}
