import { Injectable } from '@angular/core';
import { FormService } from '@app/shared/services/form/form.service';
import { EmailDto } from '@app/core/models/email-dto';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { datatypes } from '@app/core/models/datatypes';
import { information } from '@app/core/models/information';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConverterService {

  premiumValue: boolean;
  httpAddress: string;

  constructor(private formService: FormService, private http: HttpClient) {
    this.formService.isPremium.subscribe(value => {
      this.premiumValue = value;
      this.httpAddress = environment.httpAddress;
    });
  }


  sendPostRequest(): any {
    console.log('Sending email....');
    const emailDto = {} as EmailDto;

    emailDto.premium = this.premiumValue;
    emailDto.lawyer = this.formService.lawyerForm.get('lawyer').value;
    emailDto.company = this.formService.companyForm.get('firmenName').value;
    emailDto.formData = [];
    emailDto.csv = this.convertJsonToCsv();
    emailDto.formData = this.formService.mapBetterForm();

    return this.post(emailDto);
  }

  post(emailDto): Observable<any> {

    return this.http.post(this.httpAddress, emailDto, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-api-key' : environment.apiKey,
      })
    }).pipe(
      catchError(err => this.handleHttpError(err))
    );
  }

  private handleHttpError(error: HttpErrorResponse) {
    return throwError(error.message);
  }

  convertJsonToLawyerMail(): string {
    const json = this.formService.mapBetterForm();
    let lawyerMail = '';
    let counter = 1;
    json.forEach((item) => {
      lawyerMail += '' + counter + '. ' + item.name + '<br>';
      item.control.forEach(control => {
        if (control.question) {
          lawyerMail += 'Frage: ' + control.question + '<br>';
          lawyerMail += this.getAnswer(control);
          lawyerMail += this.getLawyerInformation(control);
          lawyerMail += '<br>';
        }
      });
      counter++;
    });
    return lawyerMail;
  }

  getAnswer(control: any) {
    let lawyerMail = '';
    if (control.answer === true || control.answer === 'true') {
      lawyerMail += 'Antwort: ' + 'Ja' + '<br>';
    } else if (control.answer === false || control.answer === 'false') {
      lawyerMail += 'Antwort: ' + 'Nein' + '<br>';
    } else if (control.answer === null) {
      lawyerMail += 'Antwort: ' + 'Keine Angabe' + '<br>';
    } else if (control.answer === 'andereBegrenzung') {
      lawyerMail += 'Antwort: siehe nächste Frage' + '<br>';
    } else if (control.answer === 'andereBezuschussung') {
      lawyerMail += 'Antwort: siehe nächste Frage' + '<br>';
    } else {
      lawyerMail += 'Antwort: ' + control.answer + '<br>';
    }
    return lawyerMail;
  }

  convertJsonToCsv(): string {
    let csv = '';
    const today = new Date();
    const csvHeader = 'Kategorie;Feld;Wert;Datentyp;Information';
    const csvLine1 = '0. Technisches;Programm;Versorgungsordnung;string';
    const csvLine2 = ';Version;1.0;string;';
    const csvLine3 = ';Erstelldatum;' + today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear() + ';date;';
    const json = this.formService.mapBetterForm();


    csv = csv + csvHeader + '\n' + csvLine1 + '\n' + csvLine2 + '\n' + csvLine3 + '\n';

    let counter = 1;
    json.forEach(item => {
      csv += '' + counter + '. ' + item.name;
      item.control.forEach(control => {

        if (control.key === 'entgeltumwandlungshoeheRelativ' ||
          control.key === 'entgeltumwandlungshoeheAbsolut' ||
          control.key === 'inkrafttretenTerminDatum') {
          return;
        }
        csv += ';' + control.key + ';';
        csv += this.getValue(control.answer) + ';';
        csv += this.getDataType(control.key, control.answer) + ';';
        csv += this.getInformation(control.key);
        csv += '' + '\n';
      });
      counter++;
      csv += '\n';
    });
    return csv;
  }


  getDataType(title: string, dataType: any): string {
    if (title in datatypes) {
      return datatypes[title];
    } else if (dataType in datatypes) {
      return datatypes[dataType];
    } else {
      return typeof dataType;
    }
  }

  getLawyerInformation(control: any) {
    let cleanedInformation = '';
    if (control.information) {
      cleanedInformation = control.information.replace('false', 'Nein');
      cleanedInformation = cleanedInformation.replace('true', 'Ja');
      cleanedInformation = cleanedInformation.replace(';', '');
      return 'Information: ' + cleanedInformation + '<br>';
    }
    return '';
  }


  getInformation(title: string) {
    return (title in information) ? information[title] : '';
  }


  getValue(value: string) {
    return value === null ? '' : value;
  }
}
