import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressbarService {
  private process = new BehaviorSubject<number>(8);
  percentage = this.process.asObservable();

  constructor() { }

  getPercentage(): Observable<number> {
    return this.percentage;
  }

  increasePercentage(increase: number): void {
    if (this.process.value  + increase > 100) {
      this.process.next(100);
    } else {
      this.process.next(increase + this.process.value);
    }}

  decreasePercentage(decrease: number): void {
    if (this.process.value - decrease < 0) {
      this.process.next(0);
    } else {
      this.process.next(this.process.value - decrease);
    }}
}
