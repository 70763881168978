import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FormService } from '@app/shared/services/form/form.service';
import { ProgressbarService } from '@app/shared/services/progressbar/progressbar.service';
import { PageEnum } from '@app/core/enums/page-enum';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-bav-form',
  templateUrl: './bav-form.component.html',
  styleUrls: ['../../../../../app.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BavFormComponent implements OnInit {

  bavForm: FormGroup;

  @Output() pageChanged = new EventEmitter<number>();
  @Output() scrollTop = new EventEmitter<boolean>();
  premium: Observable<boolean>;
  premiumValue: boolean;

  constructor(private formService: FormService, private progressBarService: ProgressbarService) {
  }

  onSubmit() {
    this.pageChanged.emit(PageEnum.FORM_EMPLOYERS_ALLOWANCE);
    this.progressBarService.increasePercentage(this.formService.PROCESS_STEP);
  }

  previousPage() {
    this.pageChanged.emit(PageEnum.FORM_SERVICE_SYSTEMATICS);
    this.progressBarService.decreasePercentage(this.formService.PROCESS_STEP);
  }

  ngOnInit() {
    this.bavForm = this.formService.bavForm;
    this.premium = this.formService.isPremium;
    this.premium.subscribe(value => this.premiumValue = value);
    this.scrollTop.emit(true);
  }

  get leitendeDVmitUeberProzent() {
    return this.bavForm.get('leitendeDVmitUeberProzent');
  }

  get leitendeUKmitUeberProzent() {
    return this.bavForm.get('leitendeUKmitUeberProzent');
  }

  get sonstige2DVmitUeberProzent(): AbstractControl {
    return this.bavForm.get('sonstige2DVmitUeberProzent');
  }

  get arbeitnehmerDVmitVierProzent() {
    return this.bavForm.get('arbeitnehmerDVmitVierProzent');
  }

  get arbeitnehmerUKmitVierProzent() {
    return this.bavForm.get('arbeitnehmerUKmitVierProzent');
  }

  get sonstige1text() {
    return this.bavForm.get('sonstige1text');
  }

  get sonstige1DVmitUeberProzent() {
    return this.bavForm.get('sonstige1DVmitUeberProzent');
  }

  get sonstige1UKmitUeberProzent() {
    return this.bavForm.get('sonstige1UKmitUeberProzent');
  }

  get sonstige2text() {
    return this.bavForm.get('sonstige2text');
  }

  get sonstige2UKmitUeberProzent() {
    return this.bavForm.get('sonstige2UKmitUeberProzent');
  }

  get sonstige3text() {
    return this.bavForm.get('sonstige3text');
  }

  get sonstige3DVmitUeberProzent() {
    return this.bavForm.get('sonstige3DVmitUeberProzent');
  }

  get sonstige3UKmitUeberProzent() {
    return this.bavForm.get('sonstige3UKmitUeberProzent');
  }
  enableTextField(firstCheckbox: AbstractControl, secondCheckbox: AbstractControl, input: AbstractControl) {
    if (firstCheckbox.value || secondCheckbox.value) {
      return null;
    } else {
      input.patchValue('');
      input.updateValueAndValidity();
      return '';
    }
  }

  isUpperFormValid() {
    return ((this.arbeitnehmerDVmitVierProzent.value) || (this.arbeitnehmerUKmitVierProzent.value));
  }

  isFormValid() {
    const checkboxes: Array<{ source: AbstractControl, target: AbstractControl }> = [
      {
        source: this.sonstige1DVmitUeberProzent,
        target: this.sonstige1text
      },
      {
        source: this.sonstige2DVmitUeberProzent,
        target: this.sonstige2text
      },
      {
        source: this.sonstige3DVmitUeberProzent,
        target: this.sonstige3text
      },
      {
        source: this.sonstige1UKmitUeberProzent,
        target: this.sonstige1text
      },
      {
        source: this.sonstige2UKmitUeberProzent,
        target: this.sonstige2text
      },
      {
        source: this.sonstige3UKmitUeberProzent,
        target: this.sonstige3text
      }
    ];
    let isMiscCheckboxTextValid = true;
    checkboxes.forEach(checkbox => {
      if (checkbox.source.value) {
        if (!checkbox.target.value) {
          isMiscCheckboxTextValid = false;
        }
      }
    });

    if (!this.isLowerFormValid()) {
      return false;
    }
    return this.isUpperFormValid() ? isMiscCheckboxTextValid : false;
  }

  isLowerFormValid() {
    if (!this.premiumValue) {
      if (this.isDvSelected() && this.isUkSelected()) {
        if (this.leitendeUKmitUeberProzent.value !== this.leitendeDVmitUeberProzent.value) {
          return false;
        }
        if (this.sonstige1DVmitUeberProzent.value !== this.sonstige1UKmitUeberProzent.value) {
          return false;
        }
        if (this.sonstige2DVmitUeberProzent.value !== this.sonstige2UKmitUeberProzent.value) {
          return false;
        }
        if (this.sonstige3DVmitUeberProzent.value !== this.sonstige3UKmitUeberProzent.value) {
          return false;
        }
      }
    }
    return true;
  }

  isDvSelected() {
    if (this.leitendeDVmitUeberProzent.value || this.sonstige1DVmitUeberProzent.value
      || this.sonstige2DVmitUeberProzent.value || this.sonstige3DVmitUeberProzent.value) {
      return true;
    }
    return false;
  }

  isUkSelected() {
    if (this.leitendeUKmitUeberProzent.value || this.sonstige1UKmitUeberProzent.value
      || this.sonstige2UKmitUeberProzent.value || this.sonstige3UKmitUeberProzent.value) {
      return true;
    }
    return false;
  }

  isDvEnabled() {
    if (this.premiumValue) {
      return null;
    } else {
      if (this.leitendeDVmitUeberProzent.value) {
        return null;
      } else if (this.sonstige1DVmitUeberProzent.value) {
        return null;
      } else {
        this.sonstige2DVmitUeberProzent.patchValue(false);
        this.sonstige3DVmitUeberProzent.patchValue(false);
        this.sonstige2DVmitUeberProzent.updateValueAndValidity();
        this.sonstige3DVmitUeberProzent.updateValueAndValidity();
        return true;
      }
    }
  }

  isUkEnabled() {
    if (this.premiumValue) {
      return null;
    } else {
      if (this.leitendeUKmitUeberProzent.value) {
        return null;
      } else if (this.sonstige1UKmitUeberProzent.value) {
        return null;
      } else {
        this.sonstige2UKmitUeberProzent.patchValue(false);
        this.sonstige3UKmitUeberProzent.patchValue(false);
        this.sonstige2UKmitUeberProzent.updateValueAndValidity();
        this.sonstige3UKmitUeberProzent.updateValueAndValidity();
        return true;
      }
    }
  }
}
