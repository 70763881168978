import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { ValidationMessageService } from '@app/shared/services/validation-message/validation-message.service';

@Directive({
  selector: '[appFormControlValidationMessageDirective]'
})
export class FormControlValidationMessageDirective implements OnInit {

  @Input() validationMessageId: string;
  errorSpanId = '';

  constructor(private elRef: ElementRef,
              private control: NgControl,
              private validationMessageService: ValidationMessageService
  ) {
  }

  ngOnInit(): void {
    this.errorSpanId = this.validationMessageId + new Date() + '-error-msg';
  }

  @HostListener('blur')
  handleBlurEvent() {
    if (this.control.errors) {
      this.showError();
    } else {
      this.removeError();
    }
  }

  @HostListener('input')
  handleKeydownEvent() {
    if (this.control.errors) {
      this.showError();
    } else {
      this.removeError();
    }
  }

  private showError() {
    this.removeError();
    const valErrors: ValidationErrors = this.control.errors;
    const firstKey = Object.keys(valErrors)[0];
    const errorMsgKey = this.validationMessageId + '-' + firstKey + '-msg';
    const errorMsg = this.validationMessageService.getValidationMessage(errorMsgKey);
    const errSpan = '<span style="color:red;" id="' + this.errorSpanId + '">' + errorMsg + '</span>';
    this.elRef.nativeElement.parentElement.insertAdjacentHTML('beforeend', errSpan);
  }

  private removeError(): void {
    const errorElement = document.getElementById(this.errorSpanId);
    if (errorElement) {
      errorElement.parentNode.removeChild(errorElement);
    }
  }
}
