import { NgModule } from '@angular/core';
import { BasicComponent } from '@app/view/models/forms/basic/basic.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChecklistContainerFormComponent } from '@app/view/models/forms/comfort-premium/checklist-container-form.component';
import { ModalComponent } from '@app/view/models/modal/modal.component';
import { ProgressBarComponent } from '@app/view/models/progress-bar/progress-bar.component';
import { ConsultingFormComponent } from '@app/view/models/forms/comfort-premium/consulting/consulting-form.component';
import { CompanyFormComponent } from '@app/view/models/forms/comfort-premium/company/company-form.component';
import { OperationalFormComponent } from '@app/view/models/forms/comfort-premium/operational/operational-form.component';
import { ServicesFormComponent } from '@app/view/models/forms/comfort-premium/services/services-form.component';
import { ServiceSystematicsFormComponent } from '@app/view/models/forms/comfort-premium/service-systematics/service-systematics-form.component';
import { BavFormComponent } from '@app/view/models/forms/comfort-premium/bav/bav-form.component';
import { EmployersAllowanceFormComponent } from '@app/view/models/forms/comfort-premium/employers-allowance/employers-allowance-form.component';
import { BAVPromotionFormComponent } from '@app/view/models/forms/comfort-premium/bav-promotion/bav-promotion-form.component';
import { VwlFormComponent } from '@app/view/models/forms/comfort-premium/vwl/vwl-form.component';
import { RemarksFormComponent } from '@app/view/models/forms/comfort-premium/remarks/remarks-form.component';
import { OrderPlacementFormComponent } from '@app/view/models/forms/comfort-premium/order-placement/order-placement-form.component';
import { PensionProviderComponent } from '@app/view/models/forms/comfort-premium/pension-provider/pension-provider.component';
import { InvoiceAddressComponent } from '@app/view/models/forms/comfort-premium/invoice-address/invoice-address.component';
import { CompletionComponent } from '@app/view/models/forms/comfort-premium/completion/completion.component';
import { LawyerComponent } from '@app/view/models/forms/comfort-premium/lawyer/lawyer.component';
import { ModelBarComponent } from '@app/view/models/model-bar/model-bar.component';
import { RouterModule } from '@angular/router';
import { FormService } from '@app/shared/services/form/form.service';
import { HttpClientModule } from '@angular/common/http';
import { ConverterService } from '@app/shared/services/converter/converter.service';
import { SharedModule } from '@app/shared/shared.module';
import { FinalComponent } from './forms/comfort-premium/final/final.component';
import { ErrorComponent } from './forms/comfort-premium/error/error.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    BasicComponent,
    ChecklistContainerFormComponent,
    ModalComponent,
    ProgressBarComponent,
    ConsultingFormComponent,
    CompanyFormComponent,
    OperationalFormComponent,
    ServicesFormComponent,
    ServiceSystematicsFormComponent,
    BavFormComponent,
    EmployersAllowanceFormComponent,
    BAVPromotionFormComponent,
    VwlFormComponent,
    RemarksFormComponent,
    OrderPlacementFormComponent,
    ConsultingFormComponent,
    PensionProviderComponent,
    InvoiceAddressComponent,
    CompletionComponent,
    LawyerComponent,
    ModelBarComponent,
    FinalComponent,
    ErrorComponent
  ],
  imports: [
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    NgbModule,
    RouterModule.forChild([
      {path: '', component: ChecklistContainerFormComponent},
      {path: 'versorgungsordnung-basic', component: BasicComponent}
    ])
  ], entryComponents: [
    ModalComponent,
    ChecklistContainerFormComponent
  ], providers: [
    FormService,
    ConverterService
  ]
})
export class FormModule {
}
