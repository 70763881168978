import { Component, OnInit } from '@angular/core';
import { ProgressbarService } from '../../../shared/services/progressbar/progressbar.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.sass']
})
export class ProgressBarComponent implements OnInit {

  public process;

  constructor(private progressbarService: ProgressbarService) { }

  ngOnInit() {
    this.progressbarService.percentage.subscribe(process => this.process = process);
  }

  getProcess() {
    return '' + this.process + '%';
  }

  getProgressbarColor(): string {
    if (this.process >= 90) {
      return 'progress-bar progress-bar_green';
    } else if (this.process >= 50) {
      return 'progress-bar progress-bar_yellow';
    } else {
      return 'progress-bar progress-bar_red';
    }
  }
}
