import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PageEnum } from '@app/core/enums/page-enum';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from '@app/shared/services/form/form.service';
import { environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-checklist-container-form',
  templateUrl: './checklist-container-form.component.html',
  styleUrls: ['../../../../app.component.sass', '../../../pages/landingpage/landingpage.component.sass']
})
export class ChecklistContainerFormComponent implements OnInit {

  @ViewChild('target', {static: true}) target: ElementRef;
  currentPage = 0;
  pages: any;
  premium: boolean;
  production: boolean;


  constructor(private route: ActivatedRoute, private router: Router, private formService: FormService) {
    this.production = environment.production;
  }

  ngOnInit() {
    this.pages = PageEnum;
    this.route.params.subscribe(params => {
      if (params.model === 'premium') {
        this.formService.setPremium();
      } else {
        this.formService.clearPremiumFields();
        this.formService.setComfort();
      }
    });

    this.formService.isPremium.subscribe(value => {
      this.premium = value;
    });

  }

  scroll() {
    const scrollElement = this.target.nativeElement as HTMLElement;
    scrollElement.scrollIntoView({behavior: 'smooth'});
  }

  catchEventEmitter(page: number): void {
    this.currentPage = page;
  }

  checkActive(page: number): boolean {
    return page === this.currentPage;
  }

  gotoPage(page: number): void {
    this.catchEventEmitter(page);
    this.checkActive(page);
  }

  getModel(): string {
    if (!this.premium) {
      return 'Comfort';
    }
    return 'Premium';
  }
}
