import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from '@app/shared/services/form/form.service';
import { ProgressbarService } from '@app/shared/services/progressbar/progressbar.service';
import { PageEnum } from '@app/core/enums/page-enum';
import { Observable } from 'rxjs';
import { DropDown } from '@app/shared/animations/animations';

@Component({
  selector: 'app-vwl-form',
  templateUrl: './vwl-form.component.html',
  styleUrls: ['../../../../../app.component.sass'],
  animations: [DropDown]
})
export class VwlFormComponent implements OnInit {

  vwlForm: FormGroup;

  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() scrollTop = new EventEmitter<boolean>();
  premium: Observable<boolean>;

  constructor(private formService: FormService, private progressBarService: ProgressbarService) {
  }

  ngOnInit() {
    this.vwlForm = this.formService.vwlForm;
    this.premium = this.formService.isPremium;
    this.scrollTop.emit(true);
  }

  onSubmit() {
    this.pageChanged.emit(PageEnum.FORM_PENSION_PROVIDER);
    this.progressBarService.increasePercentage(this.formService.PROCESS_STEP);
  }

  previousPage() {
    this.pageChanged.emit(PageEnum.FORM_BAV_PROMOTION);
    this.progressBarService.decreasePercentage(this.formService.PROCESS_STEP);
  }

  isAdditionalFilled() {
    return this.vwlForm.get('sonstigesVwl').value;
  }

  isFormValid() {


    const derzeitigeZahlungen = this.vwlForm.get('derzeitigeZahlungen');
    const bavStattVwl = this.vwlForm.get('bavStattVwl');
    const artVwlEntgeltumwandlung = this.vwlForm.get('artVwlEntgeltumwandlung');
    const artVwlEntgeltumwandlungBeitragshoehe = this.vwlForm.get('artVwlEntgeltumwandlungBeitragshoehe');
    const behandlungTeilzeitkraefteVwl = this.vwlForm.get('behandlungTeilzeitkraefteVwl');

    if (derzeitigeZahlungen.value === 'false') {
      return true;
    }

    if (this.isAdditionalFilled()) {
      return true;
    }

    if (derzeitigeZahlungen.value === 'true') {
      if (bavStattVwl.value === 'true') {
        if (artVwlEntgeltumwandlung.value === '0') {
          artVwlEntgeltumwandlungBeitragshoehe.patchValue(null);
          behandlungTeilzeitkraefteVwl.patchValue(null);
          return true;
        }
        if (artVwlEntgeltumwandlung.value === '1' || artVwlEntgeltumwandlung.value === '2') {
          return artVwlEntgeltumwandlungBeitragshoehe.valid ? behandlungTeilzeitkraefteVwl.value : false;
        }
      } else if (bavStattVwl.value === 'false') {
        artVwlEntgeltumwandlung.patchValue('');
        artVwlEntgeltumwandlungBeitragshoehe.patchValue(null);
        behandlungTeilzeitkraefteVwl.patchValue(null);
        return true;
      }
    }
  }
}
