import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from '@app/shared/services/form/form.service';
import { ProgressbarService } from '@app/shared/services/progressbar/progressbar.service';
import { PageEnum } from '@app/core/enums/page-enum';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-pension-provider',
  templateUrl: './pension-provider.component.html',
  styleUrls: ['../../../../..//app.component.sass']
})
export class PensionProviderComponent implements OnInit {

  premium: Observable<boolean>;
  pensionProviderForm: FormGroup;

  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() scrollTop = new EventEmitter<boolean>();

  constructor(private formService: FormService, private progressBarService: ProgressbarService) {
  }

  ngOnInit() {
    this.pensionProviderForm = this.formService.versorgungstraegerForm;
    this.premium = this.formService.isPremium;
    this.scrollTop.emit(true);
  }

  onSubmit() {
    this.pageChanged.emit(PageEnum.FORM_REMARKS);
    this.progressBarService.increasePercentage(this.formService.PROCESS_STEP);
  }

  previousPage() {
    this.pageChanged.emit(PageEnum.FORM_VWL);
    this.progressBarService.decreasePercentage(this.formService.PROCESS_STEP);
  }

}
