import { Component, OnInit } from '@angular/core';
import {InternetExplorerHelperService} from '@app/shared/services/internetExplorerHelper/internet-explorer-helper.service';

@Component({
  selector: 'app-basis',
  templateUrl: './basis.component.html',
  styleUrls: ['../../../app.component.sass', '../../pages/landingpage/landingpage.component.sass']
})
export class BasisComponent implements OnInit {

  cssClass: string;

  constructor(private internetExplorerHelper: InternetExplorerHelperService) {
  }

  ngOnInit() {
    this.cssClass = this.internetExplorerHelper.addCssClass('pt-3');
  }
}
