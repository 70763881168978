import {Component, OnInit} from '@angular/core';
import {DropDown} from '@app/shared/animations/animations';

declare var $: any;
@Component({
  selector: 'app-stylingpage',
  templateUrl: './stylingpage.component.html',
  styleUrls: ['../../../app.component.sass'],
  animations: [DropDown]
})
export class StylingpageComponent implements OnInit {

  public show: boolean;
  public showCard1: boolean;
  public showCard2: boolean;

  constructor() {
    this.show = false;
    this.showCard1 = false;
    this.showCard2 = false;
  }

  ngOnInit(): void {
  }

  toggle() {
    this.show = !this.show;
  }
}
