import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from '@app/shared/services/form/form.service';
import { ProgressbarService } from '@app/shared/services/progressbar/progressbar.service';
import { PageEnum } from '@app/core/enums/page-enum';

@Component({
  selector: 'app-services-form',
  templateUrl: './services-form.component.html',
  styleUrls: ['../../../../..//app.component.sass']
})
export class ServicesFormComponent implements OnInit {

  serviceForm: FormGroup;
  @Output() pageChanged = new EventEmitter<number>();
  @Output() scrollTop = new EventEmitter<boolean>();

  constructor(private formService: FormService, private progressBarService: ProgressbarService) {
  }

  ngOnInit() {
    this.serviceForm = this.formService.serviceForm;
    this.scrollTop.emit(true);
  }

  onSubmit() {
    this.pageChanged.emit(PageEnum.FORM_SERVICE_SYSTEMATICS);
    this.progressBarService.increasePercentage(this.formService.PROCESS_STEP);
  }

  previousPage() {
    this.pageChanged.emit(PageEnum.FORM_OPERATIONAL);
    this.progressBarService.decreasePercentage(this.formService.PROCESS_STEP);
  }

  isAltersrenteChecked() {
    return this.altersRente.value ? null : true;
  }

  setRelatedCheckboxes() {
    if (this.altersRente.value === true) {
      if (this.hinterbliebenenRente.value === true) {
        this.hinterbliebenenRente.patchValue(false);
      }
      if (this.beitragsbefreiungBU.value === true) {
        this.beitragsbefreiungBU.patchValue(false);
      }
    }
  }

  get hinterbliebenenRente() {
    return this.serviceForm.get('hinterbliebenenRente');
  }

  get altersRente() {
    return this.serviceForm.get('altersRente');
  }

  get beitragsbefreiungBU() {
    return this.serviceForm.get('beitragsbefreiungBU');
  }


}
