import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from '@app/shared/services/form/form.service';
import { ProgressbarService } from '@app/shared/services/progressbar/progressbar.service';
import { PageEnum } from '@app/core/enums/page-enum';
import { Observable } from "rxjs";

@Component({
  selector: 'app-remarks-form',
  templateUrl: './remarks-form.component.html',
  styleUrls: ['../../../../..//app.component.sass']
})
export class RemarksFormComponent implements OnInit {

  remarksForm: FormGroup;

  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() scrollTop = new EventEmitter<boolean>();
  premium: Observable<boolean>;

  constructor(private formService: FormService, private progressBarService: ProgressbarService) {
  }

  ngOnInit() {
    this.remarksForm = this.formService.anmerkungenForm;
    this.premium = this.formService.isPremium;
    this.scrollTop.emit(true);
  }

  onSubmit() {
    this.formService.isPremium.subscribe(isPremium => {
      if (isPremium) {
        this.pageChanged.emit(PageEnum.FORM_LAWYER);
      } else {
        this.pageChanged.emit(PageEnum.FORM_ORDER_PLACEMENT);
      }

      this.progressBarService.increasePercentage(this.formService.PROCESS_STEP);
    });
  }

  previousPage() {
    this.pageChanged.emit(PageEnum.FORM_PENSION_PROVIDER);
    this.progressBarService.decreasePercentage(this.formService.PROCESS_STEP);
  }
}
