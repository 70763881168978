import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormService } from '@app/shared/services/form/form.service';
import { ProgressbarService } from '@app/shared/services/progressbar/progressbar.service';
import { PageEnum } from '@app/core/enums/page-enum';
import { Observable } from 'rxjs';
import { DropDown } from '@app/shared/animations/animations';
import { dotInNumberValidator } from '@app/core/form-validators/custom-validators';

@Component({
  selector: 'app-employers-allowance-form',
  templateUrl: './employers-allowance-form.component.html',
  styleUrls: ['../../../../..//app.component.sass'],
  animations: [DropDown]
})
export class EmployersAllowanceFormComponent implements OnInit {

  employersAllowanceForm: FormGroup;
  bavForm: FormGroup;
  premium: Observable<boolean>;
  premiumValue: boolean;

  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() scrollTop = new EventEmitter<boolean>();

  constructor(private formService: FormService, private progressBarService: ProgressbarService) {
  }

  ngOnInit() {
    this.employersAllowanceForm = this.formService.arbeitgeberZuschussForm;
    this.premium = this.formService.isPremium;
    this.premium.subscribe(value => this.premiumValue = value);
    this.bavForm = this.formService.bavForm;
    this.scrollTop.emit(true);
  }

  onSubmit() {
    this.pageChanged.emit(PageEnum.FORM_BAV_PROMOTION);
    this.progressBarService.increasePercentage(this.formService.PROCESS_STEP);
  }

  previousPage() {
    this.pageChanged.emit(PageEnum.FORM_BAV);
    this.progressBarService.decreasePercentage(this.formService.PROCESS_STEP);
  }

  isAboveFourPercentSelected(): boolean {
    let bool = false;
    // tslint:disable-next-line:forin
    for (const field in this.bavForm.controls) {
      const control = this.bavForm.get(field);
      if (field.includes('Ueber')) {
        if (control.value === true) {
          bool = true;
        }
      }
    }
    return bool;
  }

  isMixedPersonenkreis() {
    return !(this.isOnlySet('UK') || this.isOnlySet('DV'));
  }

  isOnlySet(personenKreis: string): boolean {
    let opposite;
    if (personenKreis === 'DV') {
      opposite = 'UK';
    } else if (personenKreis === 'UK') {
      opposite = 'DV';
    }
    let bool = true;
    // tslint:disable-next-line:forin
    for (const field in this.bavForm.controls) {
      const control = this.bavForm.get(field);
      if (field.includes(opposite)) {
        if (control.value === true) {
          bool = false;
        }
      }
    }
    // tslint:disable-next-line:forin
    for (const field in this.bavForm.controls) {
      const control = this.bavForm.get(field);
      if (field.includes(personenKreis)) {
        if (control.value === true) {
          if (bool) {
            return bool;
          }
        }
      }
    }
    return bool;
  }

  setControlValidation(bool: boolean, key: string) {
    const field = this.employersAllowanceForm.get(key);
    const fieldAbsolute = this.employersAllowanceForm.get('entgeltumwandlungshoeheAbsolut');
    const fieldRelative = this.employersAllowanceForm.get('entgeltumwandlungshoeheRelativ');

    if (field !== null) {
      if (bool) {
        if (key === 'entgeltumwandlungshoeheAbsolut') {
          field.setValidators([Validators.required, Validators.min(1), Validators.max(10000), dotInNumberValidator]);
          fieldRelative.clearValidators();
          fieldRelative.patchValue('');
        } else {
          field.setValidators([Validators.required, Validators.min(15), Validators.max(100), dotInNumberValidator]);
          fieldAbsolute.clearValidators();
          fieldAbsolute.patchValue('');
        }
        field.updateValueAndValidity();
      }
    } else {
      fieldAbsolute.patchValue('');
      fieldRelative.patchValue('');
      fieldAbsolute.clearValidators();
      fieldRelative.clearValidators();
      fieldAbsolute.updateValueAndValidity();
      fieldRelative.updateValueAndValidity();
    }
  }

  isAdditionalFilled() {
    return this.employersAllowanceForm.get('sonstigeZuschuss').value;
  }

  isFormValid() {

    if (this.isAdditionalFilled()) {
      return true;
    }

    if (this.isOnlySet('UK')) {

      if (this.employersAllowanceForm.get('zuschussUnterstuetzungskasse').value === 'false') {
        return true;
      }
    }

    const zuschussUkGleich = this.employersAllowanceForm.get('zuschussUkGleich');
    if (zuschussUkGleich.value === 'andereBezuschussung') {
      if (this.employersAllowanceForm.get('andereBezuschussung').value) {
        return this.employersAllowanceForm.valid;
      } else {
        return false;
      }
    }

    const entgeltZuschussOberhalbVierProzent = this.employersAllowanceForm.get('entgeltZuschussOberhalbVierProzent');
    if (entgeltZuschussOberhalbVierProzent.value === 'andereBegrenzung') {
      if (this.employersAllowanceForm.get('andereBegrenzung').value) {
        return this.employersAllowanceForm.valid;
      } else {
        return false;
      }
    }
    return this.employersAllowanceForm.valid;
  }
}
