import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormService } from '@app/shared/services/form/form.service';
import { ProgressbarService } from '@app/shared/services/progressbar/progressbar.service';
import { PageEnum } from '@app/core/enums/page-enum';
import { DomSanitizer } from '@angular/platform-browser';
import { ConverterService } from '@app/shared/services/converter/converter.service';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-completion',
  templateUrl: './completion.component.html',
  styleUrls: ['../../../../../app.component.sass']
})
export class CompletionComponent implements OnInit {

  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() scrollTop = new EventEmitter<boolean>();

  premium: Observable<boolean>;
  premiumValue: boolean;
  lawyerOutput: string;

  constructor(private sanitizer: DomSanitizer,
              private formService: FormService,
              private progressBarService: ProgressbarService,
              private converterService: ConverterService) {
  }

  ngOnInit() {
    this.scrollTop.emit(true);
    this.premium = this.formService.isPremium;
    this.premium.subscribe(value => this.premiumValue = value);
  }

  onSubmit() {
    this.downloadCsv();
  }

  downloadCsv() {
    const csv = this.converterService.convertJsonToCsv();

    const blob = new Blob(['\ufeff', csv]);
    saveAs(blob, 'versorgungsordnung.csv');
  }


  previousPage() {
    this.pageChanged.emit(PageEnum.FORM_ORDER_PLACEMENT);
    this.progressBarService.decreasePercentage(this.formService.PROCESS_STEP);
  }

  executeOrder() {
    this.pageChanged.emit(PageEnum.FORM_FINAL);
    this.progressBarService.increasePercentage(100);
  }

  getSubmitText() {
    return (!this.premiumValue) ? 'Kostenpflichtiger Auftrag' : 'Mandatsanfrage';
  }

  showLawyer() {
    this.lawyerOutput = this.converterService.convertJsonToLawyerMail();
  }
}
