export const questions = {
  firmenName: "Firmenname",
  strasse: "Straße",
  plz: "Postleitzahl",
  stadt: "Stadt",

  agentenName: "Agenturname",
  agentenNummer: "Agenturnummer",
  agentenStrasse: "Agenturstraße",
  agentenPlz: "Agenturpostleitzahl",
  agentenStadt: "Agenturstadt",
  agentenTelefon: "Agenturtelefon",
  agentenMobil: "Agenturmobil",
  agentenEmail: "Agenturemail",
  agentenFax: "Agenturfax",
  agentenPasswort: "Agenturpasswort",

  firma: "Ist die Firmennameadresse angegeben?",
  vermittler: "Ist die Vermittleradresse angegeben?",
  vermittlerErklaerung: "Ist die Vermittler Erklärung akzeptiert?",
  dritter: "Ist die Drittadresse angegeben?",
  dritterName: "Name des Dritten",
  dritterStrasse: "Straße des Dritten",
  dritterOrt: "Stadt des Dritten",
  dritterPlz: "Postleitzahl des Dritten",

  tarifBindung: "Besteht eine Tarifbindung?",
  adexaADA:
    "Tarifvertrag zur betriebliche Altersvorsorge für Mitarbeiter in Apotheken und Auszubildende zur Pharmazeutisch-Kaufmännischen Angestellten (Adexa-ADA)",
  sonstigeBox: "Sonstige",
  sonstigeBeschreibung: "Sonstige Beschreibung",
  betriebsrat: "Gibt es in dem Unternehmen einen Betriebsrat?",
  betriebsratUnterschrift:
    "Soll der Betriebsrat die Betriebsvereinbarung mit unterschreiben?",
  bestehtVo:
    "Besteht in dem Unternehmen eine Versorgungsordnung (VO) bzw. Betriebsvereinbarung (BV)?",
  inkrafttretenTermin:
    "Zu welchem Termin soll die neue VO/BV in Kraft treten bzw. die bisherige ersetzen?",
  inkrafttretenTerminDatum:
    "Ab welchen Datum soll die neue VO/BV in Kraft treten?",
  inkrafttretenUnterschrift:
    "Soll die neue VO/BV ab Unterzeichnung in Kraft treten?",

  altersRente: "Ist die Altersrente eine Leistungsart?",
  beitragsbefreiungBU:
    "Ist die Beitragsbefreiuung bei Berufsunfähigkeit eine Leistungsart?",
  berufsunfaehigkeitsRente:
    "Ist die Berufsunfähigkeitsrente eine Leistungsart?",
  grundunfaehigkeitsRente: "Ist die Grundunfähigkeitsrente eine Leistungsart?",
  hinterbliebenenRente: "Ist die Hinterbliebenenrente eine Leistungsart?",

  leistungsZusage:
    "Beitragsorientierte Leistungszusage (gem. § 1 Abs. 2 Nr. 1 BetrAVG)",

  arbeitnehmerDVmitVierProzent:
    "Sollen im Rahmen von 4% der BBG im Personenkreis DV Arbeitnehmer berücksichtigt werden?",
  arbeitnehmerUKmitVierProzent:
    "Sollen im Rahmen von 4% der BBG  im Personenkreis UK Arbeitnehmer berücksichtigt werden?",
  arbeitnehmerDVmitUeberProzent:
    "Sollen oberhalb von 4% der BBG im Personenkreis DV Arbeitnehmer berücksichtigt werden?",
  arbeitnehmerUKmitUeberProzent:
    "Sollen oberhalb von 4% der BBG im Personenkreis UK Arbeitnehmer berücksichtigt werden?",
  leitendeDVmitUeberProzent:
    "Sollen oberhalb von 4% der BBG im Personenkreis DV Leitende Angestellte berücksichtigt werden?",
  leitendeUKmitUeberProzent:
    "Sollen oberhalb von 4% der BBG im Personenkreis UK Leitende Angestellte berücksichtigt werden?",
  sonstige1text: "Zusätzlicher Personenkreis oberhalb von 4% der BBG",
  sonstige1DVmitUeberProzent:
    "Sollen oberhalb von 4% der BBG im Personenkreis DV Sonstige1 berücksichtigt werden?",
  sonstige1UKmitUeberProzent:
    "Sollen oberhalb von 4% der BBG im Personenkreis UK Sonstige1 berücksichtigt werden?",
  sonstige2text: "Zusätzlicher Personenkreis oberhalb von 4% der BBG",
  sonstige2DVmitUeberProzent:
    "Sollen oberhalb von 4% der BBG im Personenkreis DV Sonstige2 berücksichtigt werden?",
  sonstige2UKmitUeberProzent:
    "Sollen oberhalb von 4% der BBG im Personenkreis UK Sonstige2 berücksichtigt werden?",
  sonstige3text: "Zusätzlicher Personenkreis oberhalb von 4% der BBG",
  sonstige3DVmitUeberProzent:
    "Sollen oberhalb von 4% der BBG im Personenkreis DV Sonstige3 berücksichtigt werden?",
  sonstige3UKmitUeberProzent:
    "Sollen oberhalb von 4% der BBG im Personenkreis UK Sonstige3 berücksichtigt werden?",

  zuschussUnterstuetzungskasse:
    "Soll die Unterstützungskasse trotz fehlender gesetzlicher Verpflichtung bezuschusst werden?",
  entgeltumwandlungshoeheRelativ: "",
  entgeltumwandlungshoeheAbsolut: "",
  entgeltumwandlungshoehe:
    "In welcher Höhe sollen Entgeltumwandlungen (dazu gehört auch der arbeitgeberfinanzierte Zuschuss zu VWL, der im Wege der Entgeltumwandlung für die bAV verwendet wird, siehe Frage 10) seitens des Arbeitgebers gefördert?",
  entgeltumwandlungseinheit: "In welcher Einheit ist diese Höhe angegeben?",
  berechnungsZuschusspauschal:
    "Soll die Berechnung des Zuschuss pauschal erfolgen?",
  berechnungZuschussspitz:
    'Soll die Berechnung des Zuschuss "Spitz abgerechnet" werden?',
  entgeltZuschussOberhalbVierProzent:
    "Soll der Zuschuss auch für Entgeltumwandlungen oberhalb von 4 % gelten, begrenzt auf 8%?",
  andereBegrenzung: "Ist eine andere Begrenzung gewünscht?",
  zuschussUkGleich:
    "Soll die Unterstützungskasse in gleicher Weise (Höhe, Begrenzung) bezuschusst werden? (wahlweise, keine gesetzliche Verpflichtung!)",
  andereBezuschussung: "Ist eine andere Bezuschussung gewünscht?",
  entgeltumwandlungsZeitpunkt:
    "Ab wann soll der künftige Zuschuss gezahlt werden? Entgeltumwandlungsvereinbarungen, die nach dem 31.12.2018 geschlossen werden, sind sofort zu bezuschussen. Ab wann sollen die Entgeltumwandlungsvereinbarungen, die vor dem 01.01.2019 geschlossen wurden, bezuschusst werden?",
  sonstigeZuschuss: "Sonstige Anmerkungen?",

  arbeitgeberBeitrag:
    "Soll ein zusätzlicher Arbeitgeberbeitrag (neben dem Arbeitgeberzuschuss) gewährt werden?",
  monatlicheBeitragshoehe:
    "In welcher Höhe soll ein Beitrag des Arbeitgebers geleistet werden?",
  durchfuehrungsWegDV:
    "Soll in dem Durchführungsweg DV der Arbeitgeberbeitrag geleistet werden?",
  durchfuehrungsWegUK:
    "Soll in dem Durchführungsweg UK der Arbeitgeberbeitrag geleistet werden?",
  ausnahmeAzubi:
    "Soll die Personengruppe Auszubildende von der Gewährung des reinen Arbeitgeberbeitrags ausgenommen werden?",
  ausnahmeBefristet:
    "Soll die Personengruppe Befristet Beschäftigte von der Gewährung des reinen Arbeitgeberbeitrags ausgenommen werden?",
  ausnahmeProbezeit:
    "Soll die Personengruppe Beschäftigte in der Probezeit von der Gewährung des reinen Arbeitgeberbeitrags ausgenommen werden?",
  ausnahmeAushilfen:
    "Soll die Personengruppe Aushilfen von der Gewährung des reinen Arbeitgeberbeitrags ausgenommen werden?",
  ausnahmeBetriebszugehoerigkeit:
    "Soll die Personengruppe Arbeitnehmer mit einer Betriebszugehörigkeit von weniger als x Monaten von der Gewährung des reinen Arbeitgeberbeitrags ausgenommen werden?",
  ausnahmePremium:
    "Soll die Personengruppe Premium von der Gewährung des reinen Arbeitgeberbeitrags ausgenommen werden?",
  ausnahmePremiumText: "Wie ist der Name der Premium-Personengruppe?",
  betriebszugehoerigkeitInMonaten: "Betriebszugehoerigkeit in Monaten?",
  behandlungTeilzeitkraefteBav:
    "Wie sollen Teilzeitarbeitskräfte behandelt werden?",
  unverfallberkeitsregelung: "Welche Unverfallbarkeitsregelung soll gelten?",
  sonstigesBav: "Sonstige Anmerkungen?",

  derzeitigeZahlungen:
    "Werden derzeit zumindest teilweise vermögenswirksame Leistungen vom Arbeitgeber (arbeitgeberfinanzierter Zuschuss) gezahlt?",
  bavStattVwl:
    "Soll eine Verwendung des arbeitgeberfinanzierten Zuschusses zu VWL stattdessen zugunsten einer bAV möglich sein?",
  artVwlEntgeltumwandlung:
    "Wie soll der arbeitgeberfinanzierte Zuschuss zu VWL im Rahmen der bAV berücksichtigt werden?",
  artVwlEntgeltumwandlungBeitragshoehe:
    "In welcher Höhe soll ein Beitrag zugunsten der bAV geleistet werden?",
  behandlungTeilzeitkraefteVwl:
    "Wie sollen Teilzeitarbeitskräfte behandelt werden?",
  sonstigesVwl: "Sonstige Anmerkungen",

  weitererAnbieterDV: "DV: NÜRNBERGER Lebensversicherung AG",
  weitererAnbieterDVpremium: "Weiterer Anbieter DV",
  weitererAnbieterUK: "UK: Nürnberger überbetriebliche Versorgungskasse e.V.",
  weitererAnbieterUKpremium: "Weiterer Anbieter UK",

  anmerkungen: "Weitere Anmerkungen?",

  lawyer:
    "Welche Kanzlei soll die Erstellung der Versorgungsordnung übernehmen?",

  agb: "Der Auftraggeber erklärt sich mit den Inhalten der AGB der NBB (einzusehen unter www.bav-infothek.de) einverstanden.",
  nbbBerechtigung:
    "Der Auftraggeber ist berechtigt, der NBB den Auftrag zu erteilen und zu diesem Zwecke die entsprechenden Daten im erforderlichen Umfang an die NBB zu übermitteln. Dies schließt auch die Weitergabe dieser Daten an die zu beauftragenden Rechtsanwälte und die betreuende Agentur (siehe oben unter Punkt 2) ein.",
  einverstaendnisUebermittlung:
    "Der Auftraggeber ist damit einverstanden, dass die Daten, die sich aus den Unterlagen oder der Auftragserteilung ergeben, an die NBB und an die beauftragten Rechtsanwälte zur Abwicklung des Auftrags übermittelt und an die betreuende Agentur (siehe oben unter Punkt 2) übersendet werden. Die Einwilligung ist jederzeit widerrufbar.",

  mandatsBerechtigung:
    "Der Auftraggeber bestätigt, dass er berechtigt ist, dem gewählten Rechtsanwalt bzw. der entsprechenden Kanzlei den Auftrag zu erteilen und zu diesem Zwecke die entsprechenden Daten im erforderlichen Umfang zu übermitteln. Die Berechtigung schließt auch die Weitergabe dieser Daten an die NÜRNBERGER Business Beratungs GmbH (im Nachfolgenden NBB) mit ein.",
  mandatsUebermittlung:
    "Der Auftraggeber ist zudem damit einverstanden, dass die Daten, die sich aus der Auftragserteilung ergeben, an die NBB übersendet werden. Die Einwilligung ist jederzeit widerrufbar.",
};
