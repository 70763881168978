import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalDirective } from '@app/shared/directives/modal.directive';
import { TooltipDirective } from '@app/shared/directives/tooltip/tooltip.directive';
import { FormControlValidationMessageDirective } from '@app/shared/directives/form-control-validation-message.directive';

@NgModule({
  declarations: [
    ModalDirective,
    TooltipDirective,
    FormControlValidationMessageDirective
  ],
  exports: [
    ModalDirective,
    TooltipDirective,
    FormControlValidationMessageDirective,
    CommonModule
  ],
  providers: [],
  imports: [
    CommonModule
  ]
})
export class SharedModule {
}
