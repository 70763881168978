import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingpageComponent } from '@app/view/pages/landingpage/landingpage.component';
import { ComfortComponent } from '@app/view/pages/comfort/comfort.component';
import { BasisComponent } from '@app/view/pages/basis/basis.component';
import { PremiumComponent } from '@app/view/pages/premium/premium.component';
import { StylingpageComponent } from '@app/view/pages/stylingpage/stylingpage.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [
    LandingpageComponent,
    ComfortComponent,
    BasisComponent,
    PremiumComponent,
    StylingpageComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  exports: [
    LandingpageComponent,
    ComfortComponent,
    BasisComponent,
    PremiumComponent,
    StylingpageComponent
  ]
})
export class PagesModule {
}
