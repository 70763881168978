export const datatypes = {
  adexaADA: 'boolean',
  sonstigeBox: 'string',
  inkrafttretenTermin: 'Date',
  artVwlEntgeltumwandlung: 'number',
  entgeltumwandlungshoehe: 'number',
  sonstige1text: 'string',
  sonstige2text: 'string',
  sonstige3text: 'string',
  zuschussUnterstuetzungskasse: 'boolean',
  behandlungTeilzeitkraefteBav: 'boolean',
  unverfallberkeitsregelung: 'boolean',
  artVwlEntgeltumwandlungBeitragshoehe: 'number',
  behandlungTeilzeitkraefteVwl: 'boolean',
  betriebszugehoerigkeitInMonaten: 'number',
  derzeitigeZahlungen: 'boolean',
  entgeltumwandlungsZeitpunkt: 'boolean',
  betriebsrat: 'boolean',
  betriebsratUnterschrift: 'boolean',
  bestehtVo: 'boolean',
  bavStattVwl: 'boolean',
  true: 'boolean',
  false: 'boolean',
  0: 'number',
  1: 'number',
  2: 'number'
};
