import {Component, EventEmitter, OnInit, Output, TemplateRef} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from '@app/shared/services/form/form.service';
import { ProgressbarService } from '@app/shared/services/progressbar/progressbar.service';
import { PageEnum } from '@app/core/enums/page-enum';
import { DropDown } from '@app/shared/animations/animations';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-invoice-address',
  templateUrl: './invoice-address.component.html',
  styleUrls: ['../../../../..//app.component.sass' , './custom-style-Modular.sass'],
  animations: [DropDown]
})
export class InvoiceAddressComponent implements OnInit {

  invoiceForm: FormGroup;
  InvoiceValueGroup: string;

  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() scrollTop = new EventEmitter<boolean>();

  constructor(private formService: FormService, private progressBarService: ProgressbarService,
              private modalService: NgbModal) {
    this.InvoiceValueGroup  = 'dritter';
  }

  ngOnInit() {
    this.invoiceForm = this.formService.invoiceForm;
    this.scrollTop.emit(true);
  }

  onSubmit() {
    this.pageChanged.emit(PageEnum.FORM_OPERATIONAL);
    this.progressBarService.increasePercentage(this.formService.PROCESS_STEP);
  }

  previousPage() {
    this.pageChanged.emit(PageEnum.FORM_CONSULTING);
    this.progressBarService.decreasePercentage(this.formService.PROCESS_STEP);
  }

  isFormValid(): boolean {
    if (this.invoiceForm.get(this.InvoiceValueGroup).value === 'dritter') {
      return this.invoiceForm.valid;
    } else if (this.invoiceForm.get(this.InvoiceValueGroup).value === 'firma' ) {
      return true;
    } else if (this.invoiceForm.get(this.InvoiceValueGroup).value === 'vermittler' &&
      this.invoiceForm.get('vermittlerErklaerung').value === true) {
      return  true;
    }
    return false;
  }

  isInternetExplorer() {
    const userAgent = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    const isIe = userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1;
    return isIe;
  }

  setVermittlerErklaerung() {
    if (this.invoiceForm.get('vermittlerErklaerung').value === false && this.invoiceForm.get('dritter').value === 'vermittler') {
     this.invoiceForm.get('dritter').patchValue(false);
    }
  }

  openPopup(event, template: TemplateRef<any>) {
    // This is needed for avoiding the ExpressionChangedAfterItHasBeenCheckedError
    // see -> https://medium.com/code-divoire/ng-bootstrap-modalservice-and-expressionchangedafterithasbeencheckederror-7b21cbf6c74a
    event.srcElement.blur();
    event.preventDefault();
    if (this.invoiceForm.get('dritter').value === 'vermittler') {
      this.modalService.open(template, { centered: true, size: 'lg' });
    }
  }
}
