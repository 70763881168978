import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormService } from '@app/shared/services/form/form.service';
import { ProgressbarService } from '@app/shared/services/progressbar/progressbar.service';
import { PageEnum } from '@app/core/enums/page-enum';
import { Observable } from "rxjs";
import { trimValidator } from '@app/core/form-validators/custom-validators';

@Component({
  selector: 'app-consulting-form',
  templateUrl: './consulting-form.component.html',
  styleUrls: ['../../../../..//app.component.sass']
})
export class ConsultingFormComponent implements OnInit {

  consultingForm: FormGroup;

  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() scrollTop = new EventEmitter<boolean>();
  premium: Observable<boolean>;

  constructor(private formService: FormService, private progressBarService: ProgressbarService) {
  }

  ngOnInit() {
    this.consultingForm = this.formService.consultingForm;
    this.scrollTop.emit(true);
    this.premium = this.formService.isPremium;
    this.premium.subscribe(value => {
      if (!value) {
        this.consultingForm.get('agentenPasswort').setValidators([Validators.required,
          Validators.minLength(5), trimValidator]);
      } else {
        this.consultingForm.get('agentenPasswort').clearValidators();
      }
      this.consultingForm.get('agentenPasswort').updateValueAndValidity();
    });

  }

  onSubmit() {
    this.pageChanged.emit(PageEnum.FORM_INVOICE_ADDRESS);
    this.progressBarService.increasePercentage(this.formService.PROCESS_STEP);
  }

  previousPage() {
    this.pageChanged.emit(PageEnum.FORM_COMPANY);
    this.progressBarService.decreasePercentage(this.formService.PROCESS_STEP);
  }

  isFormValid() {
    const phone = this.consultingForm.get('agentenTelefon');
    const mobile = this.consultingForm.get('agentenMobil');

    if ((phone.valid && phone.value) || (mobile.valid && mobile.value)) {
      return this.consultingForm.valid;
    } else {
      return false;
    }
  }

  isInternetExplorer() {
    const userAgent = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    const isIe = userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1;

    return isIe;
  }
}
