import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormService } from '@app/shared/services/form/form.service';
import { ProgressbarService } from '@app/shared/services/progressbar/progressbar.service';
import { PageEnum } from '@app/core/enums/page-enum';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['../../../../..//app.component.sass']
})
export class CompanyFormComponent implements OnInit {


  companyForm: FormGroup;
  @Output() pageChanged = new EventEmitter<number>();

  constructor(private formBuilder: FormBuilder, private router: Router,
              private progressbarService: ProgressbarService, private formService: FormService) {
  }

  ngOnInit() {
    this.companyForm = this.formService.companyForm;
  }

  get company() {
    return this.companyForm.get('firmenName');
  }

  onSubmit() {
    this.pageChanged.emit(PageEnum.FORM_CONSULTING);
    this.progressbarService.increasePercentage(this.formService.PROCESS_STEP);
  }
  get street() {
    return this.companyForm.get('strasse');
  }

  get city() {
    return this.companyForm.get('stadt');
  }

  get zip() {
    return this.companyForm.get('plz');
  }

  isFormValid() {
      return this.companyForm.valid;
  }

  isInternetExplorer() {
    const userAgent = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    const isIe = userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1;

    return isIe;
  }
}
