import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationMessageService {

  private errorMessages = {
    'semicolon-msg': 'Semikolon nicht erlaubt',
    'company-required-msg': 'Firmenname ist erforderlich',
    'street-required-msg': 'Straße ist erforderlich',
    'street-pattern-msg': 'Geben Sie eine gültige Straße an',
    'zip-required-msg': 'Postleitzahl ist erforderlich',
    'city-required-msg': 'Stadt ist erforderlich',
    'email-required-msg': ' Emailadresse ist erforderlich',
    'email-email-msg': 'Gültige Emailadresse angeben',
    'email-error-msg': 'Geben Sie eine gültige Email an',
    'agentenMobil-required-msg': 'Mindestens Telefon oder Mobil angeben',
    'agentenTelefon-required-msg': 'Mindestens Telefon oder Mobil angeben',
    'agentenMobil-validatePhone-msg': 'Mindestens Telefon oder Mobil angeben',
    'agentenMobil-pattern-msg': 'Gültige Nummer angeben',
    'agentenTelefon-pattern-msg': 'Gültige Nummer angeben',
    'agentenTelefon-validatePhone-msg': 'Mindestens Telefon oder Mobil angeben',
    'agentenName-required-msg': 'Name ist erforderlich',
    'agentenPlz-required-msg': 'Postleitzahl ist erforderlich',
    'agentenPlz-pattern-msg': 'Postleitzahl muss aus 5 Ziffern bestehen',
    'agentenStrasse-required-msg': 'Straße ist erforderlich',
    'agentenStrasse-pattern-msg': 'Geben Sie eine gültige Straße an',
    'agentenStadt-required-msg': 'Stadt ist erforderlich',
    'agentenNummer-required-msg': 'Agenturnummer ist erforderlich',
    'agentenNummer-minlength-msg': 'Agenturnummer muss aus 9 Zeichen bestehen',
    'agentenNummer-maxlength-msg': 'Agenturnummer muss aus 9 Zeichen bestehen',
    'agentenPasswort-required-msg': 'Passwort ist erforderlich',
    'agentenPasswort-minlength-msg': 'Passwort muss mindestens aus 5 Zeichen bestehen',
    'dritterName-required-msg': 'Rechnungsempfänger ist erforderlich',
    'dritterPlz-required-msg': 'Postleitzahl ist erforderlich',
    'dritterOrt-required-msg': 'Stadt ist erforderlich',
    'dritterStrasse-required-msg': 'Straße ist erforderlich',
    'dritterStrasse-pattern-msg': 'Geben Sie eine gültige Straße an',
    'inkrafttretenTermin-required-msg': 'Es muss ein Datum ausgefüllt werden',
    'bestehendeVoDatum-required-msg': 'Es muss ein Datum (DD.MM.JJJ) ausgefüllt werden',
    'bestehendeVoDatum-error-msg': 'Das Datum (DD.MM.JJJ) darf nicht in der Zukunft liegen',

    'entgeltumwandlungshoeheRelativ-required-msg': 'Prozentzahl ist erforderlich',
    'entgeltumwandlungshoeheRelativ-max-msg': 'Gültige Prozentzahl angeben',
    'entgeltumwandlungshoeheRelativ-min-msg': 'Gültige Prozentzahl angeben',

    'entgeltumwandlungshoeheAbsolut-required-msg': 'Festbetrag ist erforderlich',
    'entgeltumwandlungshoeheAbsolut-max-msg': 'Gültigen Festbetrag angeben',
    'entgeltumwandlungshoeheAbsolut-min-msg': 'Gültigen Festbetrag angeben',
    'monatlicheBeitragshoehe-required-msg': 'Festbetrag ist erforderlich',

    'company-minlength-msg': 'Geben Sie einen gültigen Firmennamen an',
    'company-pattern-msg': 'Geben Sie einen gültigen Firmennamen an',
    'street-minlength-msg': 'Geben Sie eine gültige Straße an',
    'zip-pattern-msg': 'Geben Sie eine gültige Postleitzahl an',
    'city-minlength-msg': 'Geben Sie eine gültige Stadt an',
    'agentenName-minlength-msg': 'Geben Sie einen gültigen Namen an',
    'agentenName-pattern-msg': 'Geben Sie einen gültigen Namen an',
    'agentenStrasse-minlength-msg': 'Geben Sie eine gültige Straße an',
    'agentenStadt-minlength-msg': 'Geben Sie eine gültige Stadt an',
    'agentenTelefon-minlength-msg': 'Geben Sie eine gültige Nummer an',
    'agentenMobil-minlength-msg': 'Geben Sie eine gültige Nummer an',
    'agentenEmail-minlength-msg': 'Geben Sie eine gültige Email an',
    'dritterName-minlength-msg': 'Geben Sie einen gültigen Rechnungsempfänger an',
    'dritterName-pattern-msg': 'Geben Sie einen gültigen Rechnungsempfänger an',
    'dritterStrasse-minlength-msg': 'Geben Sie eine gültige Straße an',
    'dritterPlz-pattern-msg': 'Geben Sie eine gültige Postleitzahl an',
    'dritterOrt-minlength-msg': 'Geben Sie eine gültige Stadt an',
    'sonstigeBeschreibung-minlength-msg': 'Geben Sie eine gültige Beschreibung an',

    'company-onlyWhiteSpace-msg': 'Geben Sie einen gültigen Firmennamen an',
    'street-onlyWhiteSpace-msg': 'Geben Sie eine gültige Straße an',
    'city-onlyWhiteSpace-msg': 'Geben Sie eine gültige Stadt an',


    'agentenName-onlyWhiteSpace-msg': 'Geben Sie eine gültige Stadt an',
    'agentenNummer-onlyWhiteSpace-msg': 'Geben Sie eine gültige Agenturnummer an',
    'agentenNummer-pattern-msg': 'Geben Sie eine gültige Agenturnummer an',
    'agentenStrasse-onlyWhiteSpace-msg': 'Geben Sie eine gültige Straße an',
    'agentenPlz-onlyWhiteSpace-msg': 'Geben Sie eine gültige Postleitzahl an',
    'agentenStadt-onlyWhiteSpace-msg': 'Geben Sie eine gültige Stadt an',
    'agentenTelefon-onlyWhiteSpace-msg': 'Geben Sie eine gültige Nummer an',
    'agentenMobil-onlyWhiteSpace-msg': 'Geben Sie eine gültige Nummer an',
    'agentenEmail-onlyWhiteSpace-msg': 'Geben Sie eine gültige Email an',
    'agentenPasswort-onlyWhiteSpace-msg': 'Geben Sie ein gültiges Passwort an',

    'dritterName-onlyWhiteSpace-msg': 'Geben Sie einen gültigen Rechnungsempfänger an',
    'dritterStrasse-onlyWhiteSpace-msg': 'Geben Sie eine gültige Straße an',
    'dritterOrt-onlyWhiteSpace-msg': 'Geben Sie eine gültige Stadt an',
    'sonstigeBeschreibung-onlyWhiteSpace-msg': 'Geben Sie eine gültige Beschreibung an',

    'company-containsSemicolon-msg': 'Es sind keine Semikolon erlaubt',
    'street-containsSemicolon-msg': 'Es sind keine Semikolon erlaubt',
    'zip-containsSemicolon-msg': 'Es sind keine Semikolon erlaubt',
    'city-containsSemicolon-msg': 'Es sind keine Semikolon erlaubt',


    'betriebszugehoerigkeitInMonaten-min-msg': 'Gültige Monatsangabe',
    'betriebszugehoerigkeitInMonaten-required-msg': 'Gültige Monatsangabe',
    'monatlicheBeitragshoehe-min-msg': 'Gültigen Festbetrag angeben',

    'sonstigeBeschreibung-required-msg': 'Text erforderlich',
    'ausnahmePremiumText-required-msg': 'Text erforderlich',
    'inkrafttretenTermin-unvalidDate-msg': 'Datum muss größer als das heutige sein',
    'inkrafttretenTermin-pattern-msg': 'Datumsformat in DD.MM.JJJJ angeben',
    'company-error-msg': 'Geben Sie einen gültigen Firmennamen an',
    'agentenName-error-msg': 'Geben Sie einen gültigen Namen an',
    'dritterName-error-msg': 'Geben Sie einen gültigen Rechnungsempfänger an',
    'street-error-msg': 'Geben Sie eine gültige Straße an',
    'agentenStrasse-error-msg': 'Geben Sie eine gültige Straße an',
    'dritterStrasse-error-msg': 'Geben Sie eine gültige Straße an',
    'zip-error-msg': 'Postleitzahl muss aus 5 Ziffern bestehen',
    'agentenPlz-error-msg': 'Postleitzahl muss aus 5 Ziffern bestehen',
    'dritterPlz-error-msg': 'Postleitzahl muss aus 5 Ziffern bestehen',
    'agentenTelefon-error-msg': 'Geben Sie eine gültige Nummer an',
    'agentenMobil-error-msg': 'Geben Sie eine gültige Nummer an',
    'agentenFax-error-msg': 'Geben Sie eine gültige Faxnummer an',
    'inkrafttretenTermin-error-msg': 'Datum muss größer als das heutige sein (dd.mm.jjjj)',
    'entgeltumwandlungshoeheRelativ-error-msg': 'Gültige Prozentzahl angeben',
    'entgeltumwandlungshoeheAbsolut-error-msg': 'Gültigen Festbetrag angeben',
    'monatlicheBeitragshoehe-error-msg': 'Gültigen Festbetrag angeben',
    'artVwlEntgeltumwandlungBeitragshoehe-error-msg': 'Gültigen Beitrag angeben',
    'betriebszugehoerigkeitInMonaten-error-msg': 'Gültige Monatsanzahl angeben',
  };

  public getValidationMessage(validationId: string): string {
    return this.errorMessages[validationId];
  }
}
