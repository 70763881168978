import { AfterContentInit, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { FormService } from '@app/shared/services/form/form.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['../../../app.component.sass', './modal.component.sass']
})
export class ModalComponent implements OnInit, AfterContentInit {

  public name: any;
  public div = this.ren.createElement('div');
  public keyboardListenerFn: () => void;

  title = 'Achtung: Sie wechseln ins Premium-Modell';
  @Input() isTextArea: string;

  premium: boolean;

  constructor(
    private el: ElementRef,
    private ren: Renderer2,
    private formService: FormService,
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  ngAfterContentInit() {
    this.openModal();
  }

  openModal() {
    this.ren.addClass(this.el.nativeElement.ownerDocument.body, 'modal-open');
    this.ren.appendChild(this.el.nativeElement, this.div);
    this.ren.setAttribute(this.div, 'class', 'modal-backdrop fade in');

    this.keyboardListenerFn = this.keyboardListenerFn = this.ren.listen('document', 'keydown', (event) => {
      event.preventDefault();
    });
  }

  setPremium() {
    this.router.navigate(['versorgungsordnung/premium']);
    this.formService.setPremium();
    this.closeModal();
  }

  closeModal() {
    this.ren.removeClass(this.el.nativeElement.ownerDocument.body, 'modal-open');
    this.ren.removeChild(this.el.nativeElement, this.div);
    this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
    this.removeKeyboardListener();
  }

  private removeKeyboardListener() {
    if (this.keyboardListenerFn) {
      this.keyboardListenerFn();
    }
  }
}
