import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FormService } from '@app/shared/services/form/form.service';
import { ProgressbarService } from '@app/shared/services/progressbar/progressbar.service';
import { PageEnum } from '@app/core/enums/page-enum';
import { Observable } from 'rxjs';
import { DropDown } from '@app/shared/animations/animations';
import { dotInNumberValidator } from '@app/core/form-validators/custom-validators';

@Component({
  selector: 'app-bav-promotion-form',
  templateUrl: './bav-promotion-form.component.html',
  styleUrls: ['../../../../..//app.component.sass'],
  animations: [DropDown]
})
export class BAVPromotionFormComponent implements OnInit {

  bavPromotionForm: FormGroup;
  premium: Observable<boolean>;
  @Output() pageChanged = new EventEmitter<number>();
  @Output() scrollTop = new EventEmitter<boolean>();
  premiumModel = false;

  constructor(private formService: FormService, private progressBarService: ProgressbarService) {
  }


  ngOnInit() {
    this.bavPromotionForm = this.formService.bavFoerderungForm;
    this.premium = this.formService.isPremium;
    this.scrollTop.emit(true);
    this.premium.subscribe(value => {
      this.premiumModel = value;
    });
  }

  onSubmit() {
    this.pageChanged.emit(PageEnum.FORM_VWL);
    this.progressBarService.increasePercentage(this.formService.PROCESS_STEP);
  }

  previousPage() {
    this.pageChanged.emit(PageEnum.FORM_EMPLOYERS_ALLOWANCE);
    this.progressBarService.decreasePercentage(this.formService.PROCESS_STEP);
  }


  toggleControlValidation(fieldCheckbox: AbstractControl, field: AbstractControl, validations: ValidatorFn[]) {

    if (field === this.ausnahmePremiumText) {
      // only set Validation when in premium
      if (!this.premiumModel) {
        return;
      }
    }

    if (!fieldCheckbox.value) {
      field.setValidators(validations);
      field.updateValueAndValidity();
    } else {
      field.clearValidators();
      field.updateValueAndValidity();
    }
  }

  isAdditionalFilled() {
    return this.bavPromotionForm.get('sonstigesBav').value;
  }

  isFormValid() {
    if (this.isAdditionalFilled()) {
      return true;
    }
    if (this.arbeitgeberBeitrag.value === 'true') {
      return this.bavPromotionForm.valid;
    } else {
      return this.arbeitgeberBeitrag.valid;
    }
  }


  get arbeitgeberBeitrag() {
    return this.bavPromotionForm.get('arbeitgeberBeitrag');
  }

  get ausnahmePremium() {
    return this.bavPromotionForm.get('ausnahmePremium');
  }

  get ausnahmePremiumText() {
    return this.bavPromotionForm.get('ausnahmePremiumText');
  }

  get ausnahmePremiumTextValidation() {
    return [Validators.required];
  }


  get ausnahmeBetriebszugehoerigkeit() {
    return this.bavPromotionForm.get('ausnahmeBetriebszugehoerigkeit');
  }

  get betriebszugehoerigkeitInMonaten() {
    return this.bavPromotionForm.get('betriebszugehoerigkeitInMonaten');
  }

  get betriebszugehoerigkeitInMonatenValidation() {
    return [Validators.required, Validators.min(1), dotInNumberValidator];
  }

}
