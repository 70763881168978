import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";

export const nameValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const nameRegex = new RegExp(
    "^(?!\\s)(?!.*\\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '-.,&ßÄäÖöÜüéÉèÈ]{3,}$"
  );
  return nameRegex.test(control.value)
    ? null
    : { error: "pattern not matched" };
};

export const streetValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const streetRegex = new RegExp("^([\\S\\s]+?)\\s+([\\d-\\s]*?)\\s*([\\w])?$");
  return streetRegex.test(control.value)
    ? null
    : { error: "pattern not matched" };
};

export const postCodeValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const postCodeRegex = new RegExp(
    "^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$"
  );
  return postCodeRegex.test(control.value)
    ? null
    : { error: "pattern not matched" };
};

export const phoneValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const phoneRegex = new RegExp("^[0-9-.,/() +]{6,}$");
  if (control.value.length > 0) {
    return phoneRegex.test(control.value)
      ? null
      : { error: "pattern not matched" };
  }
  return null;
};

export const dotInNumberValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const numberRegex = new RegExp("^\\d+(,\\d+)*$");
  return numberRegex.test(control.value)
    ? null
    : { error: "pattern not matched" };
};

// dd.mm.jjjj
export const dateValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const dateRegex = new RegExp(
    "^\\s*(3[01]|[12][0-9]|0?[1-9])\\.(1[012]|0?[1-9])\\.((?:19|20)\\d{2})\\s*$"
  );
  return dateRegex.test(control.value)
    ? null
    : { error: "pattern not matched" };
};

export const noFutureDateValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const dateRegex = new RegExp(
    "^\\s*(3[01]|[12][0-9]|0?[1-9])\\.(1[012]|0?[1-9])\\.((?:19|20)\\d{2})\\s*$"
  );
    // First check if its a Date dd.mm.jjjj
  if (dateRegex.test(control.value)) {
      const dateTemp = control.value.split('.');
      const dateIso = dateTemp[2] + "-" + dateTemp[1] + "-" + dateTemp[0];
      const date = new Date(dateIso);
      const today = new Date();
      return date <= today ? null : { error: "pattern not matched" };
  } else {
    return {error: "pattern not matched"};
  }

};

export const semicolonValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  return !control.value.toString().includes(";")
    ? null
    : { containsSemicolon: { valid: false, value: control.value } };
};

export const trimValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  return control.value.toString().trim().length > 2
    ? null
    : { onlyWhiteSpace: { valid: false, value: control.value } };
};

export const validDate: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const input = control.value.split(".");
  const dateInput = new Date(input[2], input[1] - 1, input[0]);

  return dateInput >= today
    ? null
    : {
        unvalidDate: {
          valid: false,
          value: control.value,
        },
      };
};

export function requireCheckboxesToBeCheckedValidator(
  minRequired = 1
): ValidatorFn {
  return function validate(formGroup: FormGroup) {
    let checked = 0;

    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.controls[key];
      if (control.value === true) {
        checked++;
      }
    });

    if (checked < minRequired) {
      return {
        requireOneCheckboxToBeChecked: true,
      };
    }

    return null;
  };
}

export const linebreakValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  return !control.value.toString().includes("\n")
    ? null
    : { error: "pattern not matched" };
};

export const customEmailValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const emailRegex = new RegExp(/(\S+@\S+\.\S+)/);
  return emailRegex.test(control.value)
    ? null
    : { error: "pattern not matched" };
};

export const specialCharacterValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  return semicolonValidator(control) && linebreakValidator(control);
};
