import {animate, style, transition, trigger} from '@angular/animations';
import {isHiddenFromScreenReader} from 'codelyzer/util/isHiddenFromScreenReader';
import {Input} from '@angular/core';

export const DropDown = trigger('DropDown', [
  transition(':enter', [
    style({height: 0, overflow: 'hidden'}),
    animate('{{delay}}ms', style({height: '*', overflow: 'hidden'}))
  ], {params : { delay: '200' }}),
  transition(':leave', [
    style({height: '*', overflow: 'hidden'}),
    animate('{{delay}}ms', style({height: 0, overflow: 'hidden'}))
  ], {params : { delay: '200' }})
]);
