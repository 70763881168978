import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FormService } from '@app/shared/services/form/form.service';
import { ProgressbarService } from '@app/shared/services/progressbar/progressbar.service';

@Component({
  selector: 'app-final',
  templateUrl: './final.component.html',
  styleUrls: ['../../../../../app.component.sass']
})
export class FinalComponent implements OnInit, OnDestroy {

  @Output() scrollTop = new EventEmitter<boolean>();
  premium: Observable<boolean>;
  premiumValue: boolean;

  constructor(private formService: FormService, private progressBarService: ProgressbarService) {
  }

  ngOnInit() {
    this.scrollTop.emit(true);
    this.premium = this.formService.isPremium;
    this.premium.subscribe(value => this.premiumValue = value);
  }

  ngOnDestroy() {
    this.progressBarService.decreasePercentage(92);
  }
}
