export enum PageEnum {
  FORM_COMPANY,
  FORM_CONSULTING,
  FORM_INVOICE_ADDRESS,
  FORM_OPERATIONAL,
  FORM_SERVICES,
  FORM_SERVICE_SYSTEMATICS,
  FORM_BAV,
  FORM_EMPLOYERS_ALLOWANCE,
  FORM_BAV_PROMOTION,
  FORM_VWL,
  FORM_PENSION_PROVIDER,
  FORM_REMARKS,
  FORM_LAWYER,
  FORM_ORDER_PLACEMENT,
  FORM_COMPLETION,
  FORM_FINAL,
  FORM_ERROR,
}
